/* FourColumns.css */

.four-columns {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }

  .technology-main .four-columns {
    grid-template-columns: repeat(5, 1fr) !important;
    display: grid;
}

.technology-main h3 {
  text-align: center;
  font-size: 36px;
}

/* .column-tech img {
  filter: brightness(1);
} */

.column-tech img:hover {
  filter: grayscale(0);
}

:root {
  --white-color: #ffffff;
  --black-color: #191e25;
  --green-color: #54a154;
  --dark-black-color: #292b32;
  --paragraph-color: #212529;
  --background-color-black-paragrpah: #bfbfbf;
}

.content_form_shopify {
  background-color: var(--white-color);
}

.inner_content {
  color: var(--black-color);
}

.shopify_clutch_logo {
  display: flex;
}

.shopifylogo {
  margin: 10px 50px 20px 0px;
}

.clutchlogo {
  margin: 22px 50px 21px 0px;
}

.contact_shopify {
  color: var(--background-color-black-paragrpah);
  display: flex;
}

.mail_shopify {
  display: flex;
  margin: 11px 50px 20px 10px;
  gap: 5px;
}
.form_shopify_hire .form_inner .card {
  padding: 25px 20px  23px 20px !important;
  background-image: unset !important;
  background-color: #509e53 !important;
}

.form_shopify_hire .form_inner .card .MuiInputBase-root {
  background: var(--white-color);
  border-radius: 4px !important;
}
.comment_sec_main button.post_btn.btn.btn-primary:hover {
  transform: unset !important;
  box-shadow: inset 0px 100px 0px 0px #000 !important;
  transition: 2s !important;
}
.form_shopify_hire .form_inner .card input::placeholder {
  opacity: 1;
  color: #fff;
}
.form_shopify_hire .form_inner .card input {
  border: 2px solid #fff;
  border-radius: 5px !important;
  height: 12px !important;
  background-color: #509e53;
  color: #fff !important;
}
.form_shopify_hire .form_inner button.btn.btn-contained:hover {
  box-shadow: inset 0px 100px 0px 0px #fff !important;
  transition: 2s !important;
  color: #000 !important;
  border: 1px solid #fff !important;
}
.form_shopify_hire .form_inner .card select {
  height: 50px;
  max-height: 50px !important;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #fff;
  font-size: 18px !important;
  padding-left: 10px !important;
  color: #fff;
  background: transparent;
}
.form_shopify_hire .hire_developer_form h2 {
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0.9px;
}
.form_shopify_hire .form_inner option {
  background: #72b755;
}
.form_shopify_hire .form_inner button.btn.btn-contained {
    padding: 10px 30px !important;
    box-shadow: unset !important;
    margin-top: -1px;
    color: #fff !important;
    width: 100% !important;
    letter-spacing: 1px !important;
    background: #191e25;
    font-weight: 600;
}
.form_shopify_hire .form_inner .card button.btn.btn-contained:hover {
  box-shadow: inset 0 100px 0 0 #191e25;
  color: var(--white-color);
  transition: 1s;
  border: 1px solid #191e25;
}

.whatsapp_shopify {
  display: flex;
  margin: 11px 50px 20px 10px;
  gap: 5px;
}

.form_inner {
  margin-left: 120px;
}

.content_whychoose {
  color: var(--background-color-black-paragrpah);
}

.titleexpertise {
  color: var(--white-color);
}

.circle_text {
  display: flex;
}

.circle_text svg {
  color: var(--green-color);
}

.circle_tick {
  color: var(--white-color);
}

.testimonials {
  background-color: #ececec;
  padding-top: 75px;
  padding-bottom: 75px;
}

span.perhour {
  font-size: 70px;
  font-weight: 700 !important;
}

.titleoftestimonial {
  text-align: center;
  margin-bottom: 50px;
}

.title_testimonial {
  color: var(--black-color);
}

.author_img {
  display: flex;
  justify-content: space-between;
}

.sopify_dev_title {
  margin-top: 15px;
  box-shadow: 0px 0px 10px 0px #ccc !important;
  padding: 20px !important;
  border-radius: 10px !important;
}

img.authorimagelogo {
  height: 22px;
}

.title_of_service {
  text-align: center;
  color: var(--white-color);
}

.hiring_process {
  padding: 20px;
  box-shadow: 0px 0px 4px 0px var(--white-color);
  height: 100%;
  border-radius: 5px;
  text-align: center;
}

.hiring_process img.requirement {
  background: #292b32;
  background: var(--dark-black-color);
  border: 1px solid #fff;
  border: 0;
  border-radius: 100%;
  height: 96px;
  margin-bottom: 20px;
  margin-top: -70px!important;
  padding: 17px;
  object-fit: cover !important;
  width: 96px !important;
  border-radius: 20px;
}
h2.hiringprocesstitle {
  padding: 9px 0 80px 0;
}

.faqs .left_side_faq {
  width: 50%;
}

.faqs .right_side_faq {
  width: 50%;
}

.expertise_section {
  color: var(--white-color);
  gap: 0px 25px !important;
  display: flex;
}

.services_shopify.row p {
  color: var(--background-color-black-paragrpah);
}

.expertiseandreviews {
  background-color: var(--white-color);
  padding: 75px 0px;
}

.hiring_process_main {
  padding-bottom: 20px;
  PADDING-TOP: 60PX;
}

.aboutpersonnel.row {
  margin-top: 20px !important;
}

p.text_expertise_reviews {
  color: var(--black-color);
}

.title_hiring {
  text-align: center;
}

.boldtext {
  margin-bottom: -20px;
  color: var(--white-color);
}

.titleofhiring {
  color: var(--background-color-black-paragrpah);
}

.images img {
  height: 220px!important;
  margin-bottom: 27px;
  width: 100%!important;
}

.images .image {
  position: relative;
}

.payment {
  padding: 184px 0px 83px 0px;
  background: #ececec;
}

.images .first {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: z-index 0.5s ease, transform 0.6s ease;
}

.images .image:hover .first {
  z-index: 0;
  background-color: red;
  transform: scale(1.05);
}

.hire_developer_form.error_paragraph p {
  color: #d92121 ;
  font-size: 14px;
  margin-left: 10px;
}

.btn_contact {
  text-align: center;
}

.title_faqs {
  text-align: center;
}

.faqs {
  background-color: white;
  padding: 70px 0px 80px 0px;
}

.titlefaqs {
  color: black;
  padding-bottom: 45px;
}

.main_content_shopifypg.row {
  padding-top: 75px;
  padding-bottom: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

p.hiring_shopify {
  color: var(--black-color);
  font-size: 20px;
}

.accordionfaq {
  display: flex;
  gap: 0px 20px;
}

.accordionfaq .MuiPaper-root {
  border-top: 1px solid #a0a0a0;
  padding: 10px 0px;
}

.hire_box_main.col-md-4 button.btn_contactus {
  background: linear-gradient(to right, #509e53, #509e53);
  border: navajowhite;
  padding: 8px 30px !important;
  color: var(--white-color);
  border-radius: 4px;
  text-transform: uppercase;
  width: 100%;
}

.hire_box_main.col-md-4 button.btn_contactus:hover {
  box-shadow: inset 0 100px 0 0 #191e25;
  color: var(--white-color);
  transition: 1s;
  cursor: pointer;
}

.whychoose_shopify {
  padding-top: 75px;
  padding-bottom: 75px;
}

.expertise.row {
  padding-top: 20px;
}

p.reviewone {
  line-height: 30px;
}

.shopify_services p.text_expertise {
  color: var(--background-color-black-paragrpah);
}

p.text_expertise {
  margin-left: 10px;
  color: var(--black-color);
}

h4.title_expertise_reviews {
  color: var(--black-color);
}

p.author {
  color: var(--black-color);
}

p.content_shopifyservices {
  color: var(--background-color-black-paragrpah);
}

p.content_title {
  font-size: 18px;
}

.shopify_services {
  padding-top: 75px;
  padding-bottom: 75px;
}

.services_shopify.row {
  padding-bottom: 0px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.svgiconss.css-i4bv87-MuiSvgIcon-root {
  font-size: 40px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.circle_tick.css-i4bv87-MuiSvgIcon-root {
  color: #54a154;
}

.first_box_hire {
  width: 80%;
  background: #fa7f00;
  border-radius: 20px;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--white-color) !important;
  margin-top: -83px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.second_box_hire {
  background: #5e9cac;
  width: 80%;
  border-radius: 20px;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--white-color) !important;
  margin-top: -83px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.third_box_hire {
  background: #38b161;
  width: 80%;
  border-radius: 20px;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--white-color) !important;
  margin-top: -83px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.hire_box_main.col-md-4 .box_shadow {
  box-shadow: 0px 0px 5px 0px #ccc !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px 30px 30px;
  border-radius: 10px !important;
  max-height: 480px;
}

.onbasispayment.row .alltext {
  height: 100% !important;
  margin-top: 16px;
}

.whychoose_shopify .text_expertise {
  color: var(--background-color-black-paragrpah);
}

.faqs .accordionfaq.row .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1c35hjw-MuiPaper-root-MuiAccordion-root {
  border: 2px solid #cbcbcb;
  border-width: 1px 0px 1px 0px;
  padding: 10px 0px;
}

.services_shopify.row .col-md-4 .service_include {
  box-shadow: 0px 0px 4px 0px var(--white-color);
  padding: 30px;
  height: 410px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.services_shopify.row .col-md-4 .service_include img.svg_icon {
  padding-bottom: 20px;
  height: 85px;
}

.expertise_section img {
  height: 70px;
}

p.service_align {
  display: flex;
  gap: 10px;
  align-items: center;
}

.portfolio_sect h2.title {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 40px;
}

.shopify_images.row {
  padding-bottom: 60px;
}

a.hire_link:hover {
  color: var(--white-color);
}
.spinner {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 120px;
    height: 80px;
    text-align: center;
    font-size: 30px;
}

.spinner>div {
    background-color: #fff;
    height: 100%;
    width: 8px;
    display: inline-block;

    animation: meregang 1.2s infinite ease-in-out;
}

.spinner .react2 {
    animation-delay: -1.1s;
}

.spinner .react3 {
    animation-delay: -1s;
}

.spinner .react4 {
    animation-delay: -0.9s;
}

.spinner .react5 {
    animation-delay: -0.8s;
}

@keyframes meregang {

    0%,
    40%,
    100% {
        transform: scaleY(0.4);
    }

    20% {
        background-color: #54a154;
        transform: scaleY(1);
    }
}
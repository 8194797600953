h1.currency_title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 2px;
    padding-bottom: 10px;
}
/* 19-07-2024 */
.curruncy_component {
    border-radius: 8px;
    background: white;
    padding: 30px;
    position: relative;
    top: 150px;
    width: 1052px;
    margin: 0 auto;
}
.currency_converter_sec {
    background: linear-gradient(45deg, #724BB7 0%, #4098D7 100%);
    padding: 30px;
    margin: 0 auto;
}
.other_section_free_tool {
    margin-top: 200px;
}
#wrapper {
    margin-top: 60px;
    position: absolute;
    perspective: 1344px;
}

#wrapper .box-area {
    position: relative;
    transform-style: preserve-3d;
    animation-name: rotate;
    animation-duration: 25s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes rotate {
    0% {
        transform: rotate3d(0, 0, 0, 0);
    }

    100% {
        transform: rotate3d(0, 1, 0, 360deg);
    }
}

#wrapper .box {
    position: fixed;
    /* width: 240px; */
    height: 240px;
    border: 2px solid #26262600;
}

#wrapper #box-front {
    transform: translateX(-120px) translateY(-120px) translateZ(120px);
    background: rgba(255, 16, 108, 0);
}

#wrapper #box-back {
    transform: translateX(-120px) translateY(-120px) translateZ(-120px);
    background: rgba(1, 251, 234, 0);
}

#wrapper #box-right {
    transform: translateY(-120px) rotateY(90deg);
    background: rgba(4, 255, 117, 0);
}

#wrapper #box-left {
    transform: translateY(-120px) translateX(-240px) rotateY(90deg);
    background: rgba(217, 255, 0, 0);
}

#wrapper #box-top {
    transform: translateX(-120px) translateY(-240px) rotateX(90deg);
    background: rgba(0, 4, 246, 0);
}

#wrapper #box-bottom {
    transform: translateX(-120px) rotateX(90deg);
    background: rgba(255, 196, 0, 0.237);
    border-radius: 2px;
    box-shadow: 0 20px 30px rgb(234, 212, 126);
    filter: blur(100px);
    border: 1px solid #d5c30000;
}

#wrapper .box-area {
    transform: rotateX(90deg) rotateY(90deg);
}
#wrapper .button {
    background: rgb(80 158 83 / 31%);
    border: 3px solid #fff;
    border-radius: 2px;
    color: #fff;
    font-weight: 400;
    padding: 125px 87px 80px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 10.2s ease-in-out;
}

#wrapper .glow-button:hover {
    box-shadow: 0 20px 30px rgba(204, 204, 202, 0.6);
}

#wrapper svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    font-size: 78px;
}

.contact_video.col-md-6 #wrapper svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    font-size: 60px !important;
}
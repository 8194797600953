:root {
    --white-color: #ffffff;
    --black-color: #191e25;
    --green-color: #54a154;
    --dark-black-color: #292b32;
    --paragraph-color: #212529;
    --background-color-black-paragrpah: #bfbfbf;
}

.careereeeeer {
    border-radius: 10px !important;
    box-shadow: 0px 0px 2px 0px #fff !important;
}

.career-image {
    position: relative;
}

.career-image .main-title {
    position: absolute;
    top: 70px;
    left: 50px;
    color: white;
}

label.custom-upload-label {
    border: 1px solid;
    height: 55px;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    color: #7a7c80;
}

.career-image .why-main {
    display: flex;
}

.career-image .why-image img {
    width: 500px;
}

.career-image .career-image img {
    width: 100%;
    height: 400px;
}

.career-image .vacancy-heading {
    text-align: center;
}

.career-image .card-head {
    display: flex;
}

.career-image .apply-btn {
    width: 80px;
    height: 50px;
}

.more {
    max-height: 500px;
    transition: max-height 0.5s ease;
}

.hide {
    max-height: 0;
    opacity: 0.5;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

/* PopupForm */
.popup-container {
    text-align: center;
}

.popup {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1006068f;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.apply-popup .popup form {
    width: 50%;
    border: none;
    margin: 0 auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 25px !important;
}

.popup input {
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
}

.popup button {
    padding: 8px 20px;
    margin-right: 10px;
    cursor: pointer;
}

.popup button:last-child {
    margin-right: 0;
}

.job-post {
    display: flex;
    gap: 100px;
}

.career-page .banner-heading.placement-banner {
    text-align: center;
    padding: 100px 0px;
    background-color: #101010 !important;
}

.career-page section.career-first-section {
    padding: 54px 0px;
    color: #fff;
    margin: 0 auto;
    width: 1280px;
    text-align: center;
}

.career-page section.why-join {
    color: #fff;
    padding: 50px 0px 60px;
    margin: 0 auto;
    width: 1210px;
}

.main-title p {
    font-weight: 300;
}

.why-content p {
    font-weight: 300;
}

.left-vacancyCnt {
    flex: 0 0 auto;
    width: 100%;
    margin-right: 20px;
}

.right-vacancyCnt {
    flex: 0 0 auto;
    width: 100%;
}

.career-page section.job-vacancy {
    color: var(--black-color);
    padding: 60px 0px;
    margin: 0 auto;
    background: #fff;
}

.right-vacancyCnt form span#successMsg {
    color: #28a745;
    text-align: center;
}

.career-page .left-vacancyCnt p {
    color: var(--black-color);
}

.right-vacancyCnt form input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 0 10px;
    border: none;
    margin-bottom: 15px;
}

.career-page .job-post {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    padding: 0px 100px;
}

.career-page .vacancy-heading h1 {
    text-align: center;
    padding: 5px 0px 0px;
}

.career-page .job-card h2 {
    font-size: 25px;
}

.career-page .why-main {
    display: flex;
}

.career-page .why-image img {
    width: 480px;
    border-radius: 30px;
    margin-left: 50px;
}

.career-page .main-title button {
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
    border: 1px;
}

.career-page .why-content {
    width: 50%;
}

.career-page .job-card {
    background-color: #000000;
    text-align: center;
    padding: 50px 40px;
    /* clip-path: polygon(0 25%,50% 0,100% 25%,100% 75%,50% 100%,0 75%); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.career-page .job-card h2 {
    font-size: 26px;
    padding: 0px 0 30px;
    color: #fff;
}

.career-page .job-card .location {
    padding: 10px 0;
    color: #fff;
}

.career-page .job-card .more h2 {
    color: var(--black-color) !important;
    font-weight: 600;
}

.career-page .job-card .more h3 {
    color: #fff !important;
    font-weight: 600;
}

.career-page .job-card button.read-more:hover {
    background-color: #28a745;
    color: #fff;
    border: 1px solid #28a745;
    transition: 0.4s;
    transform: scale(1.1);
}

.career-page .job-card button.read-more {
    background-color: #28a74500;
    color: #fff;
    padding: 6px 20px;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #fff;
    transition: transform 0.3s ease-in-out;
}

.career-page .job-card button.card-apply {
    background-color: #28a74500;
    color: #fff;
    padding: 6px 20px;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #fff;
    transition: transform 0.3s ease-in-out;
}

.career-page .job-card button.card-apply:hover {
    background-color: #28a745;
    color: #fff;
    border: 1px solid #28a745;
    transition: 0.4s;
    transform: scale(1.1);
}

.career-page .job-card:hover {
    box-shadow: 0px 0px 20px 0px #ffffff;
}

.career-page label {
    color: #fff;
}

.left-vacancyCnt h2 {
    padding-bottom: 20px;
}

.qualification {
    text-align: left;
}

.desciption ul li,
.qualification ul li {
    font-weight: 300;
    color: #fff;
}

.desciption {
    text-align: left;
}

.left-vacancyCnt h3 {
    font-size: 18px;
    padding-top: 10px;
}

.left-vacancyCnt .faq-item {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px 20px;
}

.left-vacancyCnt span {
    float: right;
    font-size: 16px;
}

.right-vacancyCnt form {
    width: 100% !important;
    padding: 2rem !important;
    background-color: #191e25;
    border-radius: 10px;
    margin-left: auto;
}

.right-vacancyCnt form .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth {
    /* box-shadow: 0px 0px 3px 0px #ccc !important; */
    border-radius: 9px !important;
}

.contactForm-inner .form-right span#successMsg {
    color: #28a745;
    padding-left: 50px;
}

.banner-heading.protfolio-banner h2 {
    color: #fff;
}

.right-vacancyCnt form input#cv {
    padding-left: 13px;
    border: none;
    font-size: 11px;
    line-height: 22px;
    padding-top: 13px;
    margin-bottom: 0px !important;
}
.right-vacancyCnt input.apply-sub.button_slide.slide_down:hover {
    box-shadow: inset 0 100px 0 0 #3a473a;
    color: #fff;
    transition: 2s;
    text-decoration: none;
}

.right-vacancyCnt input.apply-sub.button_slide.slide_down {
    margin-bottom: 0;
}

.job-vacancy .left-vacancyCnt h4 {
    padding-bottom: 30px;
    font-size: 20px;
    color: var(--black-color);
    line-height: 30px;
}

section.job-vacancy .right-vacancyCnt .form-group .MuiFormControl-root input {
    color: #fff !important;
}
/* VerticalTabs.css */
.vertical-tabs {
  width: 19%;
  overflow: hidden;
  background: transparent;
}
.main-gallery .tab {
  padding: 10px;
  text-align: center;
  background-color: #0e0e0e;
  border: 1px solid #1c1c1c;
  cursor: pointer;
  color: #fff !important;
  margin: 0px 0px 15px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 300;
}
.main-gallery .tab:hover {
  background-color: #54a154;
}
.big_fam {
  text-align: center;
}
.big_fam p {
  color: #fff;
  font-weight: 300;
  font-size: 40px;
  margin-bottom: 0;
}
.main-gallery .tab.active {
  background-color: #54a154 !important;
  color: #fff;
  border: 0px;
}
.tab-content {
  width: 100%;
  height: 450px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #54a154 !important;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #54a154; 
}
.gallery {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0px;
}
.container .main-gallery {
  margin-bottom: 60px;
  margin-top: 60px;
}
.gallery .gallery-item {
  margin: 0px !important;
  padding: 5px 0px !important;
  border: solid 0px black !important;
}
.grid-container {
  columns: 4 250px;
  column-gap: 1rem;
  width: 97%;
  margin: 0 auto;
  div {
    width: 150px;
    margin: 0 1rem 1rem 0;
    display: inline-block;
    width: 100%;
    border: solid 2px black;
    padding: 5px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    border-radius: 5px;
    transition: all .25s ease-in-out;
    &:hover img {
      filter: grayscale(0);
      transform: scale(1.02); 
    }
    &:hover {
      border-color: #54a154;
      transform: scale(1.02);
    }
    img {
      width: 100%;
      filter: grayscale(100%);
      border-radius: 5px;
      transition: all .25s ease-in-out;
    }
    p {
      margin: 5px 0;
      padding: 0;
      text-align: center;
      font-style: italic;
    }
  }
}
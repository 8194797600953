.qr_code_main {
    background-color: white;
    padding: 0px 0px 30px 0px;
}

.input_sec {
    padding: 30px 0px;
}

h1.heading_qr_code {
    padding-top: 30px;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
}

.qr_code {
    text-align: center;
}

button.down_qr_btn.btn-secondary.btn.btn-primary {
    background: black;
    border: none;
    margin-right: 10px;
    width: 30% !important;
    padding: 0px !important;
}
button.down_qr_btn.btn-secondary.btn.btn-primary:hover {
    transform: none !important;
    background: white !important;
    color: black !important;
    border: 1px solid black !important;
    padding: 0px !important;
    margin-right: 10px;
}
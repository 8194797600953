@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap");

:root {
    --white-color: #ffffff;
    --black-color: #191e25;
    --green-color: #54a154;
    --dark-black-color: #292b32;
    --paragraph-color: #212529;
    --background-color-black-paragrpah: #bfbfbf;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

button.slick-arrow.slick-next:before {
    color: green !important;
    font-size: 30px;
    position: absolute;
    top: -10px;
    right: -10px;
}

button.slick-arrow {
    z-index: 9;
}

button.slick-arrow.slick-prev:before {
    position: absolute;
    color: green !important;
    top: -10px;
    font-size: 30px;
    left: -10px;
}

.team {
    background: #e8eaef;
}

.team_section img {
    max-width: 100%;
    display: block;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: top;
}

.team_section {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    padding-block: min(20vh, 5rem);
    text-align: center;
    width: calc(min(90rem, 90%));
    margin-inline: auto;
}

.team_section p,
.team_section h2,
.team_section h3 {
    letter-spacing: 0.035rem;
}

.team_section p {
    line-height: 1.7;
    color: #454545;
    max-width: 70rem;
    margin: 0 auto;
    font-size: clamp(0.9rem, 0.825rem + 0.3vw, 1.2rem);
}

.team_section h2 {
    font-size: clamp(3rem, 2.4286rem + 2.2857vw, 5rem);
    color: #111;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 1rem;
}

section.team_section .card:hover p {
    color: white !important;
}

.team_section .cards {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(16rem, 100%), 1fr));
    gap: 2rem;
}

@media screen and (min-width: 51rem) {
    .team_section .cards {
        gap: 3.5rem;
    }
}

.team_section .cards .card {
    position: relative;
    text-align: left;
}

.team_section .cards .card .social-icon {
    background: var(--dark-black-color);
    padding: 1rem 1.75rem;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 280px;
    border-radius: 4px;
}

.team_section .cards .card .social-icon i {
    color: #fff;
    font-size: 1.4rem;
}

.team_section .cards .card .social-icon:hover i {
    color: var(--green-color);
}

.team_section .cards .card img {
    filter: grayscale(10%);
    transition: 0.5s ease;
}

.slick-dots li button:before {
    top: 2px !important;
    font-size: 12px !important;
}

section.team_section {
    padding-bottom: 100px !important;
}

.team_section .cards .card img:hover {
    filter: grayscale(0%);
}

.card:hover img {
    transform: scale(1.1);
}

.card-img-wrapper {
    overflow: hidden !important;
}

.team_section .cards .card .card-content-wrapper {
    margin-left: auto;
    max-width: 100%;
}

/* section.team_section .card .card-content-wrapper p {
    color: #2d2d2d !important;
    font-weight: 600;
}  */
.team_section .cards .card .card-content-wrapper .card-content {
    display: block;
    background: white;
    transition: 0.5s ease;
    padding: 30px;
    text-align: center;
}

.team_section .cards .card .card-content-wrapper .card-content>div {
    text-align: right;
}

.team_section .cards .card .card-content-wrapper .card-content h3,
.team_section .cards .card .card-content-wrapper .card-content p {
    text-transform: capitalize;
}

.team_section .cards .card .card-content-wrapper .card-content h3 {
    font-size: clamp(1.15rem, 1.1071rem + 0.1714vw, 1.3rem);
    font-weight: 800;
    color: #111;
}

.team_section .cards .card .card-content-wrapper .card-content p {
    color: #454545;
    font-size: 0.9rem;
}

.team_section .cards .card .card-content-wrapper .card-content svg {
    display: inline-block;
    text-align: right;
    width: 60px;
    color: var(--green-color);
}

.team_section .cards .card .card-img-wrapper:hover~.card-content-wrapper .card-content,
.team_section .cards .card .card-content-wrapper:hover .card-content {
    background: var(--green-color);
}

.team_section .cards .card .card-img-wrapper:hover~.card-content-wrapper .card-content h3,
.team_section .cards .card .card-content-wrapper .card-content:hover h3,
.team_section .cards .card .card-content-wrapper:hover .card-content h3,
.team_section .cards .card .card-content-wrapper:hover .card-content p,
.team_section .cards .card .card-content-wrapper .card-content:hover p,
.team_section .cards .card .card-content-wrapper:hover .card-content svg,
.team_section .cards .card .card-content-wrapper .card-content:hover svg {
    color: #fff;
}

.team_section .cards .card .social-icon i {

    animation: rotate 2s linear infinite;
}

@keyframes orbit {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

button.add_team {
    float: right;
    background: #27a460;
    border: none;
    margin-right: 20px;
    color: white;
    text-transform: capitalize;
    border-radius: 5px;
}

button.add_team:hover {
    float: right;
    background: #27a460;
    border: none;
    margin-right: 20px;
    color: white;
    text-transform: capitalize;
    border-radius: 5px;
}

.team_form {
    padding: 50px;
}

button.next_btn {
    border: 1px solid var(--green-color);
    color: var(--dark-black-color);
}

button.prev_btn {
    border: 1px solid var(--green-color);
    margin-right: 10px;
    color: var(--dark-black-color);
}


button.submit_team_btn {
    border: 1px solid green !important;
    color: var(--dark-black-color);
}

p.no_experience {
    text-align: center;
    margin-top: 15px;
    color: #e73a3a;
}
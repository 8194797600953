/* Banner.css */
.banner {
  position: relative;
  overflow: hidden;
}

.banner_img_hi {
  /* background: linear-gradient(45deg, black, transparent), url(http://localhost:3000/static/media/banner_img_one.78343c93f8d60da5f570.jpg); */
  background:  url('../public/images/banner/main_banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 190px 0px 150px;
}
.seo_audit_btn button.btn-play.btn.btn-primary svg {
  margin-top: 9px !important;
}
.content {
  width: 60%;
}
.exp_btn a {
  color: #fff;
  border: 1px solid #fff;
  padding: 7px 15px;
  border-radius: 10px;
  font-size: 17px;
  line-height: 30px;
  display: inline-block;
  font-weight: 400 !important;
}
.content {
  width: 50%;
}
span.icon_span svg {
  margin-bottom: -6px;
  margin-right: 0px;
  margin-left: -3px;
}
.content h1 {
  margin-bottom: 15px;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  line-height: 50px;
  letter-spacing: 1px;
}
.content p {
  color: #ebebeb;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  padding-bottom: 10px;
}


  @keyframes blink {
    0% {
      background-color: #292b32; /* Start color */
    }
    50% {
      background-color: #54a154; /* Mid color */
    }
    100% {
      background-color: #292b32; /* End color */
    }
  }

  .btn-play {
    animation: blink 1s infinite;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border: none;
    padding: 10px;
    cursor: pointer;
    outline: none;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
}
  .btn-play i {
    margin-left: 5px; /* Adjust spacing as needed */
  }

.exp_btn {
    display: flex;
    gap: 20px;
}
button.btn-play.btn.btn-primary {
  padding: 0 !important;
}
button.btn-play.btn.btn-primary:hover {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  padding: 0 !important;
  transform: scale(1) !important;
}
:root {
    --white-color: #ffffff;
    --black-color: #191e25;
    --green-color: #54a154;
    --dark-black-color: #292b32;
    --paragraph-color: #212529;
    --background-color-black-paragrpah: #bfbfbf;
}

/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Didact+Gothic&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button,
strong,
a {
    font-family: "Didact Gothic", sans-serif;
}

body,
p {
    font-size: 17px;
    letter-spacing: 0.4px !important;
}

span {
    letter-spacing: 0.4px !important;
}

/* header_css_start_Abhi */
.inner_header_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
}

li.pages_navbar .dropdown-content {
    width: -moz-fit-content;
    width: fit-content;
    max-width: 55% !important;
    text-align: left;
    position: absolute;
    left: 45% !important;
}

li.hire_us .dropdown-content {
    width: fit-content;
    max-width: 17% !important;
    text-align: left;
    position: absolute;
    left: 79% !important;
}

.column img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

a.dropbtn {
    text-transform: uppercase;
}

.inner_header_nav nav ul {
    display: flex;
    list-style: none;
    gap: 45px;
    margin: 0px;
    align-items: center;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.about-content h2 {
    color: var(--white-color);
    margin-bottom: 16px;
    text-align: left !important;
    width: 100% !important;
    padding-right: 20px;
}

.container {
    width: 100%;
    max-width: 1220px !important;
    padding: 0;
    margin: 0 auto;
}

h2 {
    font-size: 35px !important;
    color: var(--white-color);
}

.clint_name_star {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.testy_section {
    background: var(--white-color);
    padding: 81px 0px 11px 0px;
}

.item {
    padding: 25px;
}

.serv_cns {
    text-align: left;
}

.serv_cns a:hover {
    color: #54a154 !important;
    letter-spacing: 1px;
    transition: 0.4s;
}

.serv_img img {
    width: 100%;
}

.serv_img_new img {
    width: 100%;
    height: 320px;
}


.clint_name p {
    margin-bottom: 0;
}

.testi_heding h2 {
    color: var(--black-color);
    padding-bottom: 0px;
    font-size: 45px;
}

.tstim_ine p {
    color: var(--paragraph-color);
}

.clint_name_star .clint_name h3 {
    color: var(--black-color);
}

.testi_heding {
    width: 45%;
    margin-left: auto;
}

.reviw_colms {
    background: var(--white-color);
    padding: 50px 0px 60px;
}

.Testmono_clint {
    background: var(--white-color);
    padding: 30px 35px 30px 35px;
    border-radius: 7px;
    box-shadow: 0px 0px 7px 0px #474747 !important;
}

.about-banner {
    background: #101010 !important;
    margin: 20px 0px 57px !important;
}

button.owl-next span,
.owl-prev span {
    font-size: 54px;
    line-height: 41px !important;
}

.owl-next,
.owl-prev {
    width: 60px;
    height: 60px;
}

.about-inner p {
    color: var(--background-color-black-paragrpah);
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 10px;
}

.content_of_founder h2 {
    color: var(--white-color);
}

.part-about subtitle {
    width: 25%;
    text-align: left;
    font-size: 35px;
    color: var(--white-color);
}

.clint_name_star h3 {
    font-weight: 700;
    font-size: 25px !important;
}

.clint_name_star p {
    font-size: 13px;
}

.about-inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.part-about {
    padding: 15px 0px;
    margin: 0 auto;
}

.abt_heading {
    font-size: 14px !important;
    font-weight: 400;
    color: #54a154;
}

img.signature {
    float: left;
    padding-top: 0px !important;
    width: 160px;
}

.about-content p {
    font-size: 16px;
    font-weight: 300;
}

.logo_img p {
    color: var(--paragraph-color) !important;
}

.ar_line {
    position: absolute;
    height: 2px;
    width: 22px;
    background: var(--white-color);
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    top: 12px;
    left: 10px;
    transform-origin: 5px 50%;
    -webkit-transform-origin: 5px 50%;
}

.ar_left {
    position: absolute;
    height: 2px;
    width: 15px;
    background: var(--white-color);
    transform: rotate(240deg);
    -webkit-transform: rotate(240deg);
    top: 13px;
    left: 7px;
    transform-origin: 5px 50%;
    -webkit-transform-origin: 5px 50%;
    animation: leftArrow .5s 1s infinite ease-out alternate;
    -webkit-animation: leftArrow .5s 1s infinite ease-out alternate;
}

/* .arrow-right {
    width: 20px;
    height: 25px;
    position: absolute;
    top: 38px;
    transform: rotate(-90deg);
    right: 15px;
    opacity: 0;
} */
.arrow-right {
    width: 20px;
    height: 25px;
    position: absolute;
    top: 25px;
    transform: rotate(-90deg);
    right: -10px;
    opacity: 0;
    z-index: 999999999 !important;
}

.columnMenu img {
    width: 100%;
}

.inner_header_nav nav {
    display: flex;
    gap: 20px;
    align-items: center;
}

.get_hed_btn a {
    background: #54a154;
    color: var(--white-color);
    padding: 12px 15px 12px;
    font-size: 16px;
    text-decoration: none;
    border-radius: 5px;
}

/* header_css_close_abhi */
/* banner_css_start_abhi */
/* .brand_banner_section:before {
        background: #00000096;
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
    } */

.colun_frelance {
    display: flex;
    justify-content: space-between;
    margin: 20px auto !important;
    background: var(--white-color) !important;
    border-radius: 10px;
    width: 1110px;
    padding: 20px 0px;
    box-shadow: 1px 1px 20px #00000059;
}

.colun_frelance .column {
    padding: 9px 0px !important;
}

.colun_frelance .column:nth-child(2) {
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
}

.colun_frelance .column:nth-child(4) {
    border-left: 1px solid #dcdcdc;
}

/*_abut_css_ */
.two-columns.aboutInfo-section {
    gap: 40px;
}

.about-content p {
    margin-top: 0;
    margin-bottom: 0rem;
    color: var(--white-color);
}

.about_third .about-content h2,
.about_third .about-content p {
    color: #000 !important;
    text-align: left;
}

.about_us_section {
    padding: 20px 0px 65px;
    width: 100%;
}

.services-inner {
    width: 100%;
    max-width: 1220px !important;
    margin: 0 auto;
}

.technology-main {
    padding: 70px 0px 30px !important;
}

body {
    background: var(--black-color);
    position: relative;
    z-index: -1;
}

.footer_Sec {
    background-color: var(--black-color);
    color: var(--white-color);
    text-align: center;
    padding: 1rem;
}

.image-sec img {
    width: 100%;
    border-radius: 20px;
}

.about-content ul {
    padding: 0px;
    list-style: none;
}

.about-content ul li {
    font-size: 16px;
    line-height: 30px;
    color: var(--white-color);
}

.check_ico i {
    color: #54a154;
}

.experience_crown {
    background: var(--white-color)f;
    box-shadow: 0px 0px 20px #cfcfcf;
    padding: 10px;
    text-align: center;
    border-radius: 20px;
    width: 100%;
    max-width: 140px;
    position: absolute;
    left: -40px;
    top: -40px;
}

.experience_crown img {
    width: 60px;
}

.experience_crown p {
    margin-top: 0;
    margin-bottom: 0rem;
    font-size: 12px;
    font-weight: 400;
}

.experience_crown h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 3px;
}

.image-sec {
    position: relative;
}

.learn_more_btn a {
    background: #54a154;
    color: var(--white-color);
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
    text-decoration: none;
}

.image-sec::before {
    content: '';
    position: absolute;
    background: #54a154;
    width: 90%;
    height: 80%;
    top: 89px;
    left: 80px;
    z-index: -1;
    border-radius: 19px 10px;
}

/***************************************/
.testimonial-inner .slick-slide img {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    /* box-shadow: 1px 2px 4px -3px #a3a3a3; */
    /* background: #1a1a1a; */
}

.testimonial_technology img {
    width: 150px !important;
    height: 56px !important;
    border-radius: 0px !important;
}

.testimonial-inner .item h3 {
    font-size: 40px;
    margin-bottom: 0px;
    margin-top: 25px;
    color: var(--white-color);
    font-weight: 300;
    padding-bottom: 0px !important;
}

p.country {
    font-size: 16px !important;
}

span.review-date {
    font-size: 11px;
    color: var(--white-color);
    font-weight: 100;
}

.testimonial-inner span.ti-stars {
    margin-left: 135px;
    color: orange;
    font-size: 14px;
}

.testimonial-inner .slick-slide {
    display: none;
    float: left;
    margin-right: 6px;
    height: 100%;
    min-height: 1px;
}

.testimonial-inner p {
    font-weight: 300;
    font-size: 13px;
    line-height: 22px;
    margin-top: 19px;
    margin-bottom: 0px;
    color: #bdbdbd !important;
}

.testimonial-inner .slick-slide div .item {
    background: #000;
    padding: 20px;
    border-radius: 15px;
    min-height: 510px !important;
}

.testimonial-inner .slick-prev,
.testimonial-inner .slick-next {
    top: 120%;
    width: 40px;
    height: 40px;
}

.testimonial-inner .slick-initialized .slick-prev {
    right: 48% !important;
    left: unset;
}

.testimonial-inner .slick-initialized .slick-next {
    right: 45%;
}

.exp_btn a i {
    padding-left: 20px;
}

.testimonial-inner .slick-prev:before,
.testimonial-inner .slick-next:before {
    font-size: 36px;
    line-height: 1;
    opacity: 20.75;
    color: #68ac68;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/**********************************************/
.banner img {
    width: 100%;
}



.testimonial-inner h3 {
    font-size: 36px;
    font-weight: 600;
    color: #000;
    padding-bottom: 20px !important;
}

.slick-dots li.slick-active button:before {
    color: black !important;
}

.form-right h3 {
    color: #000;
    font-size: 36px;
    text-align: center;
    font-weight: 700;
}

.form-right p {
    font-size: 16px;
    line-height: 25px;
    color: #000;
    text-align: center;
}

p.subtitle {
    color: #54a154 !important;
    font-size: 16px !important;
    text-align: center;
    font-weight: 400;
    margin-bottom: 0px;
}

.client-say-pd {
    padding-top: 13px;
}

.popup-container .popup input {
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #000;
    border-radius: 5px;
}

.career-page button.apply-sub {
    background-color: #28a745;
    color: var(--white-color);
    padding: 6px 20px;
    border-radius: 3px;
    border-color: #28a745;
    border: 1px;
    margin: 20px 0;
}

.career-page button.apply-close {
    background-color: #28a74500;
    color: var(--white-color);
    margin-right: 0;
    border-radius: 3px;
    border-color: #28a745;
    border: 1px;
    text-align: right;
    width: 50%;
    margin: 0 auto;
    padding-right: 0px;
}

.fa-times-rectangle:before,
.fa-window-close:before {
    content: "\f2d3";
    font-size: 35px;
}

/***********************************************/
.footer-inner {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    text-transform: capitalize;
}

.free_consultat_inf {
    text-align: center;
    padding: 23px;
    margin-bottom: 19px;
    background: #f3f3f3;
    border: 1px solid #d7d7d74f;
}

.free_consultat_inf h4 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 15px;
}

.appintment_btn a {
    outline: 0;
    padding: 10px 15px;
    color: #fff;
    font-weight: 700 !important;
    text-shadow: 1px 1px #0000004f;
    border: 1px solid #262d37;
    background: #262d37;
    width: 56%;
    margin: 0 auto;
    border-radius: 30px;
}

p.sale_meeting_team {
    margin-top: -21px !important;
}

section.team_section.team_section .cards .card {
    position: relative;
    text-align: left;
}

.meeting_team_section .cards .card .card-content-wrapper .card-content {
    display: block;
    background: white;
    transition: 0.5s ease;
    padding: 15px;
    text-align: center;
}

.meeting_team_section .cards {
    margin-top: 2rem;
}

.get_a_free {
    text-align: center !important;
    padding-top: 70px;
    font-weight: 700;
}

.our_experts_gh {
    color: #bfbfbf;
    text-align: center;
    margin-bottom: -60px;
}

a._meeting_lin {
    color: white;
    text-decoration: none;
}

a._meeting_lin:hover {
    color: #262d37;
    text-decoration: none;
}

section.team_section .card a:hover {
    color: black !important;
}

.meeting_team_section {
    padding-bottom: 0px !important;
    padding-top: 0px;
}

.meeting_team_section .cards .card .card-content-wrapper {
    margin-left: 0 !important;
}

section.meeting_team_section .card:hover h4 {
    color: white !important;
}

.meeting_team_section .card:hover .header_btn_dot {
    text-align: center !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 0px;
    text-transform: uppercase;
    background: #fff !important;
    color: #000 !important;
}

.meeting_team_section .card:hover .header_btn_dot {
    text-align: center !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 15px;
    text-transform: uppercase;
    background: #fff !important;
    color: #000 !important;
}

section.meeting_team_section .card h4 {
    margin-bottom: 30px;
    font-weight: 700;
}

.meeting_team_section .cards .card .card-content-wrapper .card-content {
    display: block;
    background: white;
    transition: 0.5s ease;
    padding: 15px !important;
    text-align: center;
}

img.meeting_salwe_img {
    width: 100% !important;
    height: 233px;
}

.header_btn_dot {
    margin-top: 17px;
    text-align: center !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 30px;
    text-transform: uppercase;
    background: #509e53 !important;
    color: #fff !important;
}

section.team_section.meeting_team_section h4 {
    font-size: 20px;
}

.header_btn_dot {
    margin-top: 17px;
    text-align: center !important;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 14px;
    font-size: 15px;
    text-transform: uppercase;
    background: #509e53 !important;
    color: #fff !important;
}

.main_meeting {
    min-height: 90px;
    text-align: center !important;
}


section.meeting_team_section .cards .card {

    height: auto !important;
}

p.sale_meeting_team {
    line-height: 17px;
}

.free_consultat_inf img {
    width: 250px;
    border-radius: 50%;
}

.social_media-icon {
    list-style: none;
    display: flex;
    gap: 17px;
    padding-left: 0px !important;
}

.apply_now {
    font-size: 15px;
    margin-bottom: 12px;
    color: #54a154;
    font-weight: 600;
}

.consult_hover {
    position: relative;
    animation: myfirst 2s infinite;
    animation-direction: alternate;
}

.exp_btn a:hover {
    border: 1px solid #509e53;
    box-shadow: inset 0 100px 0 0 #509e53;
    color: #fff;
    color: var(--white-color);
    text-decoration: none;
    transition: 1s;
}

@keyframes myfirst {
    0% {
        left: 0px;
        top: 0px;
    }

    25% {
        left: 10px;
        top: 0px;
    }

    50% {
        left: 0px;
        top: 0px;
    }

    75% {
        left: 10px;
        top: 0px;
    }

    100% {
        left: 0px;
        top: 0px;
    }
}

.quick-links,
.portfolio-links {
    text-align: left;
    list-style: none;
    padding: 0px;
}

.footer-logo {
    text-align: left;
    display: flex;
}

.column h3 {
    text-align: left;
    font-size: 24px;
    margin-bottom: 20px;
}

.footer-address {
    text-align: left;
    display: flex;
    gap: 10px;
    color: var(--white-color);
    padding-right: 20px;
}

.footer-phone,
.footer-email {
    display: flex;
    margin: 15px 0px;
}

.footer-phone a span,
.footer-email a span {
    margin-right: 10px;
    font-size: 20px;
}

.footer-phone a,
.footer-email a {
    color: var(--white-color);
    display: flex;
    align-items: center;
}

.btn-primary {
    transition: transform 0.3s ease-in-out;
    color: var(--white-color);
    background-color: #54a15400;
    border-color: #54a154;
    padding: 8px 40px;
    /* border: 1px solid var(--white-color); */
    height: 42px !important;
    width: 134px !important;
}

.btn-primary:hover {
    transform: scale(1.1);
    background-color: #54a154 !important;
    color: var(--white-color) !important;
    border: none !important;
    padding: 8px 40px !important;
    height: 42px !important;
    width: 134px !important;
    transition: 0.4s;
}

.slick-track {
    display: flex !important;
    justify-content: center;
}

ul.portfolio-links li,
ul.quick-links li {
    margin: 10px 0;
}

.form-group .MuiFormControl-root input {
    color: var(--black-color);
    font-size: 15px;
    font-weight: 500;
}

textarea#message::placeholder {
    color: #9b9b9b;
    font-size: 15px;
    font-weight: 500;
}

li.hire_us a:hover {
    color: var(--green-color) !important;
}

li.pages_navbar a:hover {
    color: var(--green-color) !important;
}

.dropbtn:hover {
    color: var(--green-color) !important;
}

.faq-inner .question span:hover {
    color: var(--green-color) !important;
}

.footer-inner ul li a,
.footer-inner ul li a {
    color: var(--white-color);
    font-size: 17px;
    text-decoration: none;
    font-weight: 300;
    display: flex;
}

.footer-inner ul li a:hover,
.footer-inner ul li a:hover {
    color: #54a154 !important;
}

.right-content-abt {
    width: 90%;
}

.footer-copy-text p {
    margin-top: 0;
    margin-bottom: 0rem;
}

ul.social_media-icon li {
    background: transparent;
    padding: 10px 12px;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
    color: var(--white-color);
}

.inner-header {
    position: absolute;
    z-index: 1;
    width: 100%;
}

.placement-inner p {
    color: var(--white-color);
    text-align: center;
    font-size: 16px;
}

.column.footer-address-main {
    max-width: 35%;
}

footer .column {
    flex: 1 1;
    padding: 20px 20px 20px 0px;
    text-align: center;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

/***********************************************/
.whatsapp_widget_wrapper {
    width: 300px;
    float: right;
}

.whatsapp_widget_icon {
    border-radius: 50px;
    bottom: 60px;
    z-index: 9999999999 !important;
    float: right;
    padding: 10px;
    position: fixed;
    right: 23px;
}

.whatsapp_widget_header_close_btn div {
    text-align: right;
}

.whatsapp_widget_icon {
    border-radius: 50px;
    padding: 10px 10px;
    float: right;
    position: fixed;
    bottom: 67px;
    right: 50px;
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.whatsapp_widget_pop {
    width: 300px;
    position: fixed;
    bottom: 75px;
    right: 90px;
}

.whatsapp_widget_header {
    display: flex;
    flex-direction: row-reverse;
    background: #54a154 !important;
    padding: 15px 15px;
    justify-content: space-between;
}

.whatsapp_widget_chat_wrapper_inner {
    background: var(--white-color);
    padding: 20px;
}

.whatsapp_widget_footer {
    background: #54a154 !important;
    padding: 15px;
    border-radius: 0px 0px 10px 20px;
}

.whatsapp_widget_header_icon {
    margin-right: 10px;
    background: transparent !important;
}

.whatsapp_widget_header_icon img {
    width: 50px;
    border-radius: 30px;
    height: 50px;
    object-fit: cover;
}

.whatsapp_widget_header {
    display: flex;
    flex-direction: row-reverse;
    color: var(--white-color)f !important;
    background: #54a154 !important;
    padding: 15px 15px;
    justify-content: space-between;
}

input.whatsapp_widget_input {
    width: 100%;
    padding: 11px 10px;
    border: 0px;
    font-size: 11px;
    margin-bottom: 0px;
    color: #7a7a7a;
    border-radius: 10px 0px 0px 10px;
}

a.whatsapp_widget_footer_btn {
    background: #000 !important;
    padding: 11px 10px;
    border-radius: 0px 10px 10px 0px;
    font-size: 11px;
    color: var(--white-color) !important;
    white-space: nowrap;
}

.whatsapp_widget_footer {
    background: #54a154 !important;
    padding: 15px;
    border-radius: 0px 0px 10px 20px;
    margin-top: -10px;
}

.whatsapp_widget_footer {
    display: flex;
}

.whatsapp_widget_footer div {
    width: 100%;
}

/***********************************************/
.onTop i {
    color: #54a154;
    font-size: 35px;
    line-height: 45px;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #54a154;
}

.onTop {
    position: fixed;
    bottom: 10px;
    background: transparent;
    border: transparent;
    right: 20px;
    z-index: 1;
}

button:focus {
    outline: none;
}

span.ti-stars i:hover {
    animation: star .5s cubic-bezier(.36, .07, .57, .99) infinite;
    transform: scale(1.5);
}

.two-columns.aboutInfo-section .about-content-columns.about_project .about-content p {
    padding-right: 50px;
}

nav {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.service_img {
    padding: 8px;
    display: flex;
    align-items: center;
}

.inner_header_nav nav ul a {
    position: relative;
    display: block;
    cursor: pointer;
    padding: 8px;
    color: var(--white-color);
    text-decoration: none;
    font-size: 15px !important;
}

.columnMenu h3 {
    font-size: 27px !important;
    font-weight: 700;
}

.logo img {
    width: 200px;
    margin-top: -6px;
}

.inner_header_nav nav ul a:hover::before {
    content: '';
}

.inner_header_nav nav ul a:hover::after {
    content: '';
}

ul>li:hover .arrow-right {
    opacity: 1;
}


@keyframes vibrate {
    0% {
        transform: translateX(-1px) translateY(-1px);
    }

    100% {
        transform: translateX(1px) translateY(1px);
    }

    60% {
        transform: rotate(-3deg);
    }
}


.vibrate {
    animation: vibrate 0.3s infinite;
}

.get_hed_btn {
    color: #111;
    font: 500 1rem sans-serif;
    position: relative;
}

.get_hed_btn::before,
.get_hed_btn::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border: 0px solid transparent;
}

.get_hed_btn::before {
    top: -15px;
    left: -4px;
    border-top: 2px solid #54a154;
    border-left: 2px solid #54a154;
    transition: 1s;
    z-index: -9999;
    border-radius: 5px;
}

.get_hed_btn::after {
    bottom: -18px !important;
    right: -5px;
    border-bottom: 2px solid #54a154;
    border-right: 2px solid #54a154;
    transition: 1s;
    z-index: -9999;
    border-radius: 5px;
}

.contact_us_section .contact_us_second .container .col-md-6 form {
    padding: 50px 0px 20px 0px !important;
}

.get_hed_btn:hover::before,
.get_hed_btn:hover::after {
    width: calc(96% + 6px);
    height: calc(96% + 6px);
}

/*********** By Amru *******************************/
.four-columns.colun_frelance .column {
    overflow: hidden;
}

.colun_frelance .column {
    transition: 0.3s;
    text-align: center;
}

.colun_frelance .column:hover {
    transform: scale(1.1);
}

.column ul.social_media-icon i {
    font-size: 20px;
}

.footer-email i,
.footer-phone i,
.footer-address i {
    font-size: 20px;
    color: var(--white-color);
    background: transparent;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fa-map-marker:before {
    content: "\f041";
    font-size: 28px;
}

.footer-address i.fa.fa-map-marker:hover {
    background-color: #54a154;
    color: var(--white-color) !important;
    border-radius: 100%;
    padding: 10px !important;
}

.footer-phone i.fa.fa-phone-square:hover {
    background-color: #28a745;
    color: var(--white-color) !important;
    border-radius: 100%;
    padding: 10px !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.footer_icon.css-i4bv87-MuiSvgIcon-root {
    color: var(--white-color);
    font-size: 25px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.footer_icon.css-i4bv87-MuiSvgIcon-root:hover {
    color: #38c268;
}

.footer-email i.fa.fa-envelope:hover {
    background-color: #28a745;
    color: var(--white-color) !important;
    border-radius: 100%;
    padding: 10px !important;
}

a:hover {
    text-decoration: none !important;
}

/******************* Lucky *****************************/
section#schedules .schedule .activity video {
    padding-top: 100px !important;
}

section#schedules .showcase {
    margin-bottom: 50px !important;
}

.column-tech {
    border-right: 1px solid #707070 !important;
    padding-right: 5px !important;
    text-align: center;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.column-tech img {
    padding: 10px !important;
}

.testimonial.testi_clints {
    background-color: var(--white-color) !important;
    padding-bottom: 80px !important;
}

.colun_frelance {
    margin-top: -50px !important;
}


.testimonial.testi_clints {
    padding-bottom: 144px !important;
    padding-top: 40px;
}

.slick-dots li button:before {
    font-size: 9px !important;
    position: absolute !important;
    top: 40px !important;
    opacity: 0.7 !important;
    color: #28a745 !important;
}

.review-page .testimonial.testi_clints {
    background-color: #000000 !important;
}

.blog_detail_form_main .hire_developer_form h2 {
    font-size: 30px !important;
    font-weight: 600;
}

.blog_detail_form_main .hire_developer_form p {
    color: red;
    font-size: 19px;
    letter-spacing: 0.9px !important;
}

.client_review_row {
    gap: 30px;
}

.testimonial .tecnoLogo {
    padding-top: 15px;
}

.review-page .reviesOfClient {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
}

.review-page .testimonial-inner h3 {
    color: var(--white-color);
    padding-top: 15px !important;
}

.review-page .reviesOfClient .item {
    background-color: #101010;
    padding: 20px;
    border-radius: 10px;
}

.banner-heading.review-banner h2 {
    color: var(--white-color);
}

.review-page .reviesOfClient .item:hover {
    box-shadow: 0px 0px 20px 0px #343434d9;
}

.review-page .reviesOfClient .item img {
    width: 100px;
}

.review-page .testimonial-inner .item h3 {
    font-size: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
    color: var(--white-color);
    font-weight: 300;
    padding-bottom: 0px !important;
}

.review-page .testimonial.testi_clints {
    padding-bottom: 0px !important;
    padding-top: 40px;
}

.why-join .why-content p {
    font-size: 17px;
    color: #bfbfbf;
}

.placement-main .form-fields-button {
    display: inline-flex;
    width: 50%;
    margin-top: 30px;
}

/******************* Amarjeet *****************************/
.placement-main {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #ccc;
    padding-bottom: 50px;
}

.placement-main .form-fields-button {
    margin-top: 35px;
}

input.profile_Q.ans_Qus {
    width: 16px;
    height: 16px;
}

.profile-inner label {
    font-size: 16px;
}

.form-fields-button.back-btn {
    justify-content: end;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    width: 16px;
    height: 16px;
}

.placement-inner {
    width: 100%;
    max-width: 1070px;
    padding-top: 100px;
}

.common-fields input {
    width: 100%;
}

.placement-main label {
    font-size: 20px;
    padding-right: 20px;
}

.profile-inner label {
    padding-left: 20px;
}

.form-fields-button input {
    width: 100%;
    max-width: 120px;
    border-radius: 6px;
    font-size: 18px;
    background: #54a154;
    font-weight: bold;
    color: var(--white-color);
}

.questionLable {
    color: var(--white-color);
    padding-bottom: 30px;
    text-align: center;
    color: #191e25;
}


.testimonial .tecnoLogo img {
    width: 100px;
    height: auto;
    object-fit: cover;

}

button.form_submit_btn {
    padding: 10px;
    height: 50px;
    width: 100%;
    color: white;
    font-weight: 600;
}

.contactForm-inner .form-group {
    margin-bottom: 1rem;
}

.contact_video.col-md-6 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px !important;
}

.contact_us_first h5.MuiTypography-root.MuiTypography-h5.css-ag7rrr-MuiTypography-root {
    color: var(--dark-black-color) !important;
}

.contact_us_section h5,
h6 {
    color: var(--background-color-black-paragrpah) !important;
    font-size: 21px;
    padding-top: 20px;
    padding-bottom: 5px;
}

.contact_us_second .contact_media h6 svg {
    color: #4ba457;
    font-size: 30px;
}

.contact_us_second .container .col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact_us_section .contact_us_second input::placeholder {
    color: #fff !important;
}

.contact_us_section .contact_us_second textarea::placeholder {
    color: #595959;
}

.contact_us_section .contact_us_second input {
    background: var(--black-color) !important;
    box-shadow: 0px 0px 3px 0px var(--white-color) !important;
    border-radius: 5px;
    color: var(--white-color);
}

.contact_us_section .contact_us_second textarea {
    color: var(--white-color);
    background: var(--black-color) !important;
    box-shadow: 0px 0px 3px 0px #ffffff !important;
    border-radius: 5px;
    border: none;
}

.contact_des h3 {
    padding-bottom: 20px;
}

h3.MuiTypography-root.MuiTypography-h3.css-18h9l83 {
    background: linear-gradient(to right, #148156, #9fd959);
    background-clip: text;
    color: transparent;
    font-weight: 700;
    font-size: 40px;
}

.contact_media {
    margin-top: 15px;
}

.contact_us_second .contact_media h6 {
    padding: 10px 0px;
    display: flex;
    gap: 15px;
    font-size: 16px;
    width: 70%;
}

.contact_us_first span.MuiTypography-root.MuiTypography-p.css-37jdci-MuiTypography-root {
    font-size: 17px;
    font-weight: 500;
    color: #212529 !important;
}

.social-icons svg {
    margin-top: 5px;
    width: 35px;
}

input.contact_submit_btn {
    /* color: red; */
    background: #fafafa;
    width: 100%;
    border: none;
    border-radius: 8px;
    color: #509e53;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 20px;
    transition: background-color .3s, transform .2s;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}

.contact_us_first {
    border-bottom: 3px solid var(--green-color);
    color: white;
    padding: 80px 0px !important;
    background: #ccc;
}

.contact_us_second {
    color: var(--background-color-black-paragrpah);
}

.contact_us_section {
    background: var(--black-color);
}

.contact_us_first img {
    width: 100%;
    max-width: 70%;
}

.common-fields {
    grid-template-columns: repeat(2, 1fr) !important;
    display: grid;
    gap: 0px 20px;
}

.left-fields {
    grid-template-columns: repeat(2, 1fr) !important;
    display: grid;
    gap: 20px;
    padding-bottom: 20px;
}

/********************************/
.placement-page h2 {
    text-align: center;
    color: var(--white-color);
}

.placement-inner form {
    padding-top: 30px;
}

.profile-inner .form-group {
    margin-bottom: 0;
}

.profile-inner {
    margin-bottom: 1rem;
}

/***************** IndustryDemands start *************************/
.industryDemands {
    max-width: 1340px;
    margin: 0 auto;
}

.industryDemands-inner {
    padding: 57px 20px 58px;
    max-width: 1170px;
    margin: 0 auto;
}

.industryDemands-inner h2 {
    margin-bottom: 50px;
    text-align: center;
    color: var(--white-color);
    font-size: 45px;
    line-height: 1.2;
    margin-bottom: 20px;
}

.industryDemands-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    padding-top: 2rem;
}

.industryDemands-items .col {
    text-align: center;
    border-right: 1px solid #3c3c3c;
    border-bottom: 1px solid #3c3c3c;
    padding: 40px 50px;
}

.industryDemands-items .col:hover img {
    transform: scale(1.1);
}

.btn-primary:hover {
    color: #000;
    background-color: var(--white-color);
    border-color: var(--white-color);
}

.industryDemands-items .col a,
.industryDemands-items .col a h3 {
    text-decoration: none;
    color: var(--white-color);
    font-weight: 400;
    line-height: 1.3;
    padding-top: 20px;
    font-size: 16px;
}

.industryDemands-items .col a,
.industryDemands-items .col a p {
    color: #c5c5c5;
    font-size: 12px;
}

.services-inner .service-row .col p {
    color: #000;
    text-align: left;
    padding-top: 5px;
    font-size: 15px;
    margin-top: 0px;
}

.four-columns .colun_frelance.column img {
    width: 100%;
    border-radius: 8px;
}

.industryDemands-items .col:hover h3 {
    color: #54a154;
    transform: scale(1.1);
}

.industryDemands-items .col img {
    border-radius: 10px;
    background-image: linear-gradient(to bottom, #343338, #302e34, #2b2a2f, #27252b, #232127);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
}

.industryDemands-items .col:nth-child(4n) {
    border-right: 0 solid var(--paragraph-color);
}

.industryDemands-items .col:nth-child(9),
.col:nth-child(10),
.col:nth-child(11),
.col:nth-child(12) {
    /* border-bottom: 1px solid var(--paragraph-color) !important; */
}

/***************** IndustryDemands end *************************/
.form-left.col-6 {
    padding: 50px;
}

.form-right .form-group input {
    height: 40px;
}

.placement-inner .questionAnswer-main {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
}

.placement-inner .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    /* border: 1px solid #54a154; */
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    /* box-shadow: 0px 0px 4px 0px #509e53; */
    height: 50px !important;
    cursor: pointer;
    border: none;
    font-weight: 600;
}

select#traing_exp {
    color: #636363 !important;
    font-weight: 600;
    font-size: 14px;
}

div#errorMsg {
    color: red;
    margin-top: 20px;
}

div#contactForm {
    padding-top: 0px !important;
    padding-right: 0px !important;
}

.questionAnswer-main .answer {
    display: flex;
    align-items: baseline;
}

.questionAnswer-main .answer label {
    font-size: 16px;
    color: #ebebeb;
}

/********************* About Us Page Start *********************/
.subtitle {
    color: #54a154;
}

.page-about {
    padding-top: 30px;
}

.page-about .banner-heading h2 {
    text-align: center;
    padding: 100px 0px;
}

.about-main {
    margin-bottom: 22px;
}

.about-inner h2 {
    padding: 6px 0px;
}

.about-content h2,
.about-content p {
    color: var(--white-color) !important;
    text-align: center;
}

.about_third {
    background: var(--white-color);
    display: flex;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 58px;
}

.about-content p {
    text-align: left;
}

.about_third .about-content-columns {
    width: 100%;
    margin: 0 auto;
}

.top-counter {
    display: block;
    width: 100%;
    gap: 0px;
}

.about_third .about_us_section {
    padding: 0 !important;
    width: 65%;
}

/************** Join Our Team **********************/
.join-team {
    background-color: #f7f7f7;
    padding: 47px 0px 30px;
}

.join-team .join-team-inner {
    display: flex;
}

.join-team .left-side {
    width: 50%;
    padding-right: 20px;
}

.join-team .right-side {
    width: 50%;
}

.join-team .content {
    max-width: 400px;
}

.join-team h3 {
    font-size: 35px;
    color: var(--black-color);
    margin-bottom: 10px;
}

.join-team p {
    font-size: 16px;
    line-height: 1.3;
    color: #666;
    margin-bottom: 30px;
}

.join-team a {
    display: inline-block;
    padding: 10px 20px;
    background-color: transparent;
    color: #000;
    text-decoration: none;
    border: 1px solid #000;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    font-weight: 600;
}

.join-team .grid-images {
    display: flex;
}

.join-team .display-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.join-team .img-wrap {
    width: 50%;
    padding: 10px;
}

.join-team .img-wrap img {
    width: 100%;
    border-radius: 5px;
}

.join-team .img-wrap-inner {
    padding: 5px;
    padding-bottom: 20px;
}

.join-team .left-side {
    width: 50%;
    padding-right: 20px;
    align-items: center !important;
    display: flex;
}

.content_of_founder {
    width: 72%;
}

.img_of_founder {
    width: 40%;
}

.img_of_founder img {
    width: 100%;
    border-radius: 20px;
}

.founder_section_content {
    display: flex;
    gap: 50px;
    align-items: center;
    padding: 0px 0px;
}

span.question_in {
    font-weight: 600;
    font-size: 18px;
    color: #000 !important;
}

.faq-main .faq-item {
    border-radius: 5px;
}

.technology-main.slick-initialized .slick-slide {
    opacity: 1 !important;
}

.read_more {
    text-align: left;
    padding-top: 10px;
}

.read_more a {
    background: var(--green-color);
    padding: 10px 19px;
    border-radius: 5px;
    color: var(--white-color);
    font-size: 14px;
}

.content_of_founder p {
    text-align: left;
    color: var(--background-color-black-paragrpah);
}

.founder_section {
    padding: 70px 0px;
}

/********************* About Us Page End *********************/
.tabs li:hover {
    /* transform: scale(1.1); */
    box-shadow: 0px 0px 11px #2125293d;
    transition: .3s;
    display: block;
    padding: 10px 15px !important;
    background: #54a154 !important;
    margin-right: 0px !important;
    border-radius: 6px;
    cursor: pointer;
    color: var(--white-color) !important;
}

.tabs li {
    display: block;
    border-radius: 6px;
    cursor: pointer;
    color: var(--white-color);
    padding: 10px 15px !important;
}

.tabs {
    display: flex;
    justify-content: center;
    gap: 25px !important;
    padding: 0 !important;
}

.footer-inner ul.quick-links li a:hover {
    border-bottom: none;
}

.footer_description {
    text-align: left;
    padding: 10px;
    padding-left: 5px;
}

.footer-inner .column h3 {
    border-bottom: 2px solid #457a45;
    white-space: nowrap;
    width: 65px;
    padding-bottom: 9px;
    padding-top: 0px;
}

footer {
    padding: 60px 0px 20px !important;
}

.footer-copy-text .home-link {
    color: #54a154;
}

.footer-copy-text span.term:hover,
.footer-copy-text span.privacy:hover {
    color: #54a154;
    cursor: pointer;
}

.invalid-feedback {
    text-align: left;
}

.banner-heading.placement-banner h2 {
    color: var(--white-color);
}

.error-message {
    margin-left: 10px;
    color: #ff0033;
}

/* Training & Placement css start here  */
.placement-page .banner-heading.placement-banner {
    padding: 100px 0px;
    background-color: #101010 !important;
}

.questionAnswer-main .answer {
    color: var(--white-color);
}

.answer input.profile_Q.ans_Qus {
    margin-right: 20px;
}

/* Training & Placement css end here  */

/*review-page css start here*/

h1.rev_hi {
    font-size: 40px !important;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    padding-bottom: 30px;
}

.banner-heading.review-banner h2 {
    background: #101010 !important;
    padding: 100px 0px;
    padding-bottom: 100px;
    text-align: center;
    margin-bottom: 0;
}

/*review-page css end here*/
/* contact page css start here */
.contact-page-inner {
    padding-top: 100px;
}

.contact-page-inner .page-about .banner-heading h2 {
    text-align: center;
    background: #101010 !important;
    padding: 100px 0;
    padding-bottom: 100px;
    margin-bottom: 0;
    color: var(--white-color);
}

.main-contact-page .section-420 {
    width: 1280px;
    margin: 0 auto;
    text-align: center;
    color: var(--white-color);
    padding: 50px 0px;
}

.main-contact-page .section-420 .heading-contact h3 {
    font-size: 40px;
    font-weight: 600;
}

.section-420 span.anitime {
    color: #28a745;
}

.section-420 span.anitime:hover {
    color: var(--white-color);
}

.contact-section-430 {
    display: flex;
    justify-content: space-around;
    padding-bottom: 0px;
    padding-top: 0px;
    width: 1280px;
    margin: 0 auto;
}

.contact-section-430 .icon {
    padding: 10px 20px;
    background-color: var(--white-color);
    border-radius: 100%;
    font-size: 30px;
}

.contact-section-430 .content-1,
.content-2,
.content-3 {
    color: var(--white-color);
}

.contact-section-430 i {
    color: var(--white-color);
    font-size: 30px !important;
}

.contact-section-430 .contact-item-3 {
    width: 25%;
    background-color: #101010;
    border-radius: 5px;
    padding: 20px;
}

.contact-section-430 .contact-item-2 {
    background-color: #101010;
    border-radius: 5px;
    padding: 20px;
    width: 25%;
}

.contact-section-430 .contact-item-1 {
    background-color: #101010;
    border-radius: 5px;
    padding: 20px;
    width: 40%;
}

.contact-item-1 h2 {
    color: #28a745;
    font-size: 30px;
    padding-top: 20px;
}

.contact-item-2 h2 {
    color: #28a745;
    font-size: 30px;
    padding-top: 20px;
}

.contact-item-3 h2 {
    color: #28a745;
    font-size: 30px;
    padding-top: 20px;
}

.contact-item-1:hover {
    box-shadow: 0px 0px 20px 0px var(--white-color);
}

.contact-item-2:hover {
    box-shadow: 0px 0px 20px 0px var(--white-color);
}

.contact-item-3:hover {
    box-shadow: 0px 0px 20px 0px var(--white-color);
}

.bk-ph {
    background-color: #28a745;
    padding: 15px 20px;
    border-radius: 100%;
    width: fit-content;
}

.bk-add {
    background-color: #28a745;
    padding: 15px 25px;
    border-radius: 100%;
    width: fit-content;
}

.bk-em {
    background-color: #28a745;
    padding: 15px 18px;
    border-radius: 100%;
    width: fit-content;
}

/* contact page css end here */
.main-sub {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 0px 75px 0px;
}

.Subject-M p {
    text-align: right;
    width: -webkit-fill-available;
    background-color: #544b4b36;
    padding: 40px;
    margin: 20px 0px 20px 20px;
    border-radius: 10px;
}

.Subject-M h2 {
    text-align: left;
    font-size: 25px;
}

.Subject-M h3.cont2 {
    font-size: 18px;
}

.main-sub .database_left h3 {
    color: var(--white-color);
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 50px;
    padding-right: 30px;
}

.testimonial-inner .slick-track {
    gap: 80px;
}

#root>div.page>div.our-services>div>div.servic_provid>div>div:nth-child(1)>div>div>div.back>div.read_more {
    MARGIN-TOP: 35PX !IMPORTANT;
}

.testimonial-inner .slick-slide.slick-active.slick-center.slick-current {
    width: 850px !important;
    opacity: 1;
}

.slick-initialized .slick-slide {
    display: block;
}

.main-sub .databaseIcon.Subject-M {
    background: #171717;
    border: 1px solid #343434;
    border-radius: 10px;
    height: 240px;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-sub .databaseIcon.Subject-M:hover {
    transform: scale(1.1);
    transition: 0.7s;
    cursor: pointer;
}

/*******************************************/

.main-sub .database_right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 0;
}

.main-sub .database_left {
    padding-right: 20px;
    display: flex;
    align-items: center;
}

.faq-inner .question span {
    color: var(--black-color);
}

.faq-main h2 {
    color: var(--black-color);
    line-height: 1.2;
    padding-left: 15px;
    text-align: left;
    padding-bottom: 25px;
}

.faq-main .question {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    margin: 0;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
}

.faq-item span.icon {
    padding-left: 38px;
    margin-right: 9px;
}

.faq-main .faq-item {
    overflow: hidden;
    transition: auto;
    margin: 15px 0px 15px 0px;
    padding: 15px 20px;
    background: #f1f2fd;
    height: -moz-fit-content;
    height: fit-content;
    width: 630px;
}

.form-left h3 {
    font-size: 40px;
}

.form-left p {
    font-size: 25px;
}

.faq-main .answer {
    overflow: hidden;
    color: var(--paragraph-color);
    transition: max-height 0.8s ease-out;
    padding: 0px 10px 0px 10px;
}

.faq-main .question svg {
    position: absolute;
    align-items: center;
    transform: rotate(90deg);
    width: 25px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #000;
    height: 30px;
    cursor: pointer;
    stroke-width: 1px;

}

.faq-main .question:hover {
    color: #28a745;
}

.faq-main .active svg {
    transform: rotate(-90deg) !important;
}

/********** Tushar css start here **********/
.join-team a:hover {
    box-shadow: inset 0 100px 0 0 #191e25;
    color: var(--white-color);
    transition: 1s;
    border: 1px solid #191e25;
}

button.Next_btn_traning_and_placement {
    padding: 12px 50px;
    border: none;
    color: var(--white-color) !important;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: transparent;
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    background: #509e53;
}

section.heading .banner-heading.placement-banner {
    background-color: #101010 !important;
}

section.heading .banner-heading.placement-banner h2 {
    color: var(--white-color) !important;
}

.career-page .main-title button {
    transition: transform 0.3s ease-in-out;
    background-color: transparent !important;
    border: 1px solid var(--white-color) !important;
    color: var(--white-color) !important;

}

.career-page .main-title button:hover {
    transform: scale(1.1);
    background-color: #54a154 !important;
    color: var(--white-color) !important;
    border: 1px solid #54a154 !important;
    transition: 0.4s;
}

.blog_detail_form_main label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeMedium.MuiInputLabel-outlined.css-ayzozo-MuiFormLabel-root-MuiInputLabel-root {
    color: #507a37;
}

img.blog_image {
    width: 100%;
    height: 420px;
    object-fit: cover;
    border-radius: 20px;
    margin: 0px 0px 20px 0;
}

button.back_btn_placement_traning {
    display: block;
    padding: 10px 40px;
    font-size: 1rem;
    background-color: transparent !important;
    color: var(--white-color);
    background-clip: padding-box;
    border: 1px solid var(--white-color);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    height: 50px !important;
    cursor: pointer;
    font-weight: 700;
    background: #509e53 !important;
}

button.back_btn_placement_traning:hover {
    background-color: #54a154 !important;
    color: var(--white-color);
    border: 1px solid #54a154;
}

.read-btn a {
    background-color: #0000 !important;
    border: 1px solid #000 !important;
    color: #000 !important;
    font-weight: 600 !important;
    transition: transform 0.3s ease-in-out;
}

.read-btn a:hover {
    background-color: #54a154 !important;
    border: 1px solid #54a154 !important;
    color: var(--white-color) !important;
    font-weight: 600 !important;
    transform: scale(1.1);
    transition: 0.4s;
}

li.review_nav .row .col-md-6 {
    text-align: left !important;
}

.column.footer-address-main a {
    align-items: unset !important;
}

/********** Tushar css End here ***********/

li.review_nav .dropdown-content {
    width: 55% !important;
    left: 45%;
}

.header_btn {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 30px;
    text-transform: uppercase;
    background: #509e53 !important;
}

.header_btn:hover {
    padding: 8px 30px !important;
    font-weight: 700;
    box-shadow: inset 0 100px 0 0 #ffffff;
    color: #000 !important;
    transition: 1.4s;
    text-decoration: none;
}

.header_btn:hover a {
    color: #000 !important;
    font-weight: 400 !important;
}

button.modal_button:hover {
    font-weight: 700;
    box-shadow: inset 0 100px 0 0 #191e25;
    color: #fff;
    transition: 2s;
}

.header_btn a {
    color: var(--white-color) !important;
    letter-spacing: 0.5px;
    font-size: 15px !important;
}

.button_slide {
    background: #54a154;
    padding: 10px 30px;
    border-radius: 10px;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 1px;
    color: var(--white-color);
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #54a154;
    transition: ease-out 0.4s;
}

.career-page-inner .right-vacancyCnt button:hover {
    box-shadow: inset 0 100px 0 0 #ebebeb;
    color: #000 !important;
}

/* .contact_us_section .container .col-md-6 form button:hover {
    box-shadow: inset 0 100px 0 0 #ebebeb;
    color: #000 !important;
} */

.slide_down:hover {
    box-shadow: inset 0 100px 0 0 #191e25;
    color: var(--white-color);
    transition: 1s;
    text-decoration: none;
}

.read_more a:hover {
    box-shadow: inset 0 100px 0 0 #191e25;
    color: var(--white-color);
    transition: 2s;
    text-decoration: none;
}

.read-btnn {
    text-align: center;
    margin-top: 60px;
}

div#successMsg {
    color: #54a154;
    padding: 10px;
    font-size: 20px;
}

.our-services .services-inner p.litle-des {
    width: 100%;
    margin: 0 auto;
}

.banner-heading.about-banner h2 {
    color: var(--white-color);
}

.section-420 .des p {
    color: #c5c5c5;
    font-size: 16px;
}

.service .our-services .read-btnn {
    display: none;
}

.column ul.social_media-icon i:hover {
    background-color: #54a154;
    color: var(--white-color) !important;
    border-radius: 100%;
    padding: 10px !important;
}

.faq_Contact_Sec {
    background-color: var(--white-color) !important;
}

.faq_Contact_Sec {
    background-color: var(--white-color) !important;
    padding: 70px 0px;
}

.faq_Contact_Sec .container {
    display: flex;
}

.dropdown-content .service_img a:hover {
    color: var(--green-color) !important;
}

.main-contact-page .contactForm-inner {
    display: flex;
    justify-content: center;
}

.main-contact-page .form-right {
    width: 660px;
    padding: 2rem !important;
}

.main-contact-page .p-5 {
    padding: 0rem !important;
}

.closeMenus .closeIcon {
    display: none;
}

.main-contact-page .form-right h3 {
    color: #eadede;
    font-size: 36px;
    text-align: center;
}

.main-contact-page .form-right p {
    color: #eadede;
    text-align: center;
}


/* The dropdown container */
.dropdownMenu {
    float: left;
    /* overflow: hidden; */
}

/* Dropdown button */
.dropdownMenu .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: var(--white-color);
    padding: 0;
    background-color: inherit;
    font: inherit;
    padding: 8px 0px;
}

.client_des {
    font-size: 12px;
}

.client_name {
    margin-bottom: 4px;
    color: var(--white-color);
}

.client_des {
    font-size: 11px;
    color: #969696;
    margin-top: -1px;
}

.testimonial_modifi {
    background: #000;
    padding: 0px 25px 30px;
    border-radius: 10px;
}

.profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.testimonial_modifi {
    background: #000;
    padding: 20px 70px 30px;
}

.testi_img_con {
    border-top: 1px solid #585858;
    padding-top: 20px;
}


.tesi_conte p {
    min-height: 190px;
    color: var(--white-color) !important;
    font-size: 16px;
    line-height: 27px;
    padding-top: 20px;
}


/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--dark-black-color) !important;
    border-radius: 5px;
    width: 89%;
    left: 0;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 20px;
    top: 60px;
    width: 100% !important;
}


/* Mega Menu header, if needed */
.dropdown-content .header {
    background: red;
    padding: 16px;
    color: white;
}

/* Show the dropdown menu on hover */
.dropdownMenu:hover .dropdown-content {
    display: block;
}

/* Create three equal columns that floats next to each other */
.columnMenu {
    float: left;
    width: 33.33%;
    padding: 10px;
    height: auto;
}

.columnMenu {
    text-align: left;
}

.col-md-6.serv_img img {
    height: 325px;
}

.dropdown-content a {
    color: var(--white-color) !important;
}

/* Style links inside the columns */
.columnMenu a {
    float: none;
    color: var(--dark-black-color);
    padding: 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

/* Add a background color on hover */
.columnMenu a:hover {
    color: var(--green-color) !important;
}


/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

header {
    /* Your regular header styles go here */
    transition: background-color 0.3s ease;
}

header.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    z-index: 999999999;
}

.sticky .inner-header {
    background: var(--black-color);
    box-shadow: 0px 0px 3px 0px var(--white-color) !important;
}

/* luc */
.aboutInfo-section .about-content .container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.page-about .aboutInfo-section .about-content .container {
    display: block !important;
    justify-content: space-between;
    gap: 10px;
}

.about_page .about_project .about-content {
    flex-direction: column;
    gap: 0px;
}

.aboutInfo-section .about_project h1.text-blue-600 {
    width: 100%;
}

.aboutInfo-section .about_project .about-content p {
    width: 100%;
}

.about_page .aboutInfo-section .about_project .about-content h1 {
    color: #000;
    font-weight: 700 !important;
}

.about_page {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 20px 0px 20px;
}

.aboutInfo-section .about_project .about-content h1 {
    color: var(--white-color);
    font-weight: 700 !important;
}

.aboutInfo-section h1.text-blue-600 {
    width: 45%;
}

.aboutInfo-section .about-content p {
    width: 50%;
}

.owl-theme .owl-nav {
    text-align: right !important;
    -webkit-tap-highlight-color: transparent;
    width: 47%;
    position: absolute;
    bottom: 60px;
}

.banner.brand_banner_section.banner_img_hi .content p {
    width: 70%;
}

.about_us_banner_iamge {
    background-image: url('/public/images/about/aboutbanner.jpg');
    background-size: cover;
    width: 100% !important;
    background-repeat: no-repeat;
    background-position: center;
    padding: 80px 0px;
    box-shadow: inset 0px 2000px 0px #000000ba;
    background-attachment: fixed;
}

.main_header p {
    text-transform: capitalize;
    color: var(--white-color);
    font-size: 18px;
    padding-top: 10px;
    width: 60%;
    margin: auto;
    text-align: center;
}

.main_header {
    position: relative;
    /* Allows absolute positioning within */
    width: 100%;
    /* Full width */
    height: 350px;
    /* Set a fixed height for the container */
    background-attachment: fixed;
}

.main_header_background-image {
    width: 100%;
    /* Responsive width for the image */
    height: 100%;
    /* Ensures the image takes the full height of the container */
    object-fit: cover;
    /* Ensure the image fills the container without distortion */
    object-position: center;
    /* Center the image within the container */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.main-heading_title {
    color: var(--white-color);
    font-size: 18px;
    padding-top: 10px;
    width: 60%;
    margin: auto;
    text-align: center;
    position: absolute;
    /* Positions relative to the image container */
    top: 50%;
    /* Centers vertically */
    left: 50%;
    /* Centers horizontally */
    transform: translate(-50%, -50%);
    /* Translates to align to center */
    text-align: center;
    /* Aligns text to the center */

    border-radius: 5px;
    /* Optional: rounded corners */
}

.main-services {
    background: url('/public/images/about/aboutbanner.jpg');
    background-size: cover;
    width: 100% !important;
    background-repeat: no-repeat;
    background-position: center;
    padding: 140px 0px;
    box-shadow: inset 0px 2000px 0px #000000ba;
    background-attachment: fixed;
}

.main-heading_title h1 {
    color: var(--white-color);
    text-transform: capitalize;
    text-align: center;
}

.row.banner_two_rows {
    justify-content: center;
    text-align: center;
}


.abous_secons_main {
    padding-top: 80px !important;
}

.col-md-7.banner_twoes_divs p {
    color: var(--white-color);
    font-size: 18px;
    padding-top: 10px;
}

.col-md-7.banner_twoes_divs h1 {
    font-size: 40px !important;
    color: var(--white-color);
    font-weight: 700;
    font-family: Montserrat;
    text-transform: uppercase;
    font-weight: 700;
}

.col-md-5.banner_twoes_divse {
    text-align: right;
    padding-top: 100px !important;
}

.card-counter:hover {
    box-shadow: 0px 0px 6px 0px #54a154 !important;
    transition: transform 1.1s ease !important;
    transform: scale(1.1);
}

.tab_panel.port_content .col-md-4 {
    width: 100% !important;
    max-width: 100% !important;
}

li.hire_us .dropdown-content {
    max-width: 55% !important;
    left: 45% !important;
}

.service_img.pph_icon .dropdown-content {
    display: none;
}

.service_img.pph_icon:hover .dropdown-content {
    display: block !important;
}

.service_img.pph_icon .dropdown-content {
    box-shadow: inset 0 0 5px 0 #fff !important;
    margin-left: 14px;
    max-width: 460px !important;
    top: 25px;
    width: 460px !important;
    z-index: 99999999 !important;
}


.modal_form .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-feqhe6 {
    margin-bottom: 20px;
}

.term_con_sec {
    background-color: var(--white-color);
}

.term_cond_main h3 {
    color: var(--dark-black-color);
    text-align: left;
}

.policy_con_sec {
    background-color: var(--white-color);
}

.term_cond_main p {
    color: var(--dark-black-color);
}

.privacy_policy_main h3 {
    color: var(--dark-black-color);
    text-align: left;
    margin-top: 20px;
}

.privacy_policy_main p {
    color: var(--dark-black-color);
    padding-bottom: 20px !important;
}

.main_term_condtion {
    background-color: #ebecec;
    padding: 75px 0px;
    box-shadow: inset 0px 0px 100px 0px #ccc !important;
}

.privacy_policy_main {
    background: #ffff;
    width: 80%;
    margin: 0 auto !important;
    padding: 50px 50px 30px 50px;
    box-shadow: 0px 0px 6px 0px #ccc;
}

.dropdown-content.fiverr_navbar {
    top: 50% !important;
}

.dropdown-content.guru_navbar {
    top: 70% !important;
}

.contact_form .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-953pxc-MuiInputBase-root-MuiInput-root {
    margin-bottom: 20px !important;
}

.left_modal {
    background-image: url('../public/get_in.avif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.left_modal h3.title_modal {
    font-size: 35px;
    padding: 20px 0px 0px 30px;
    font-weight: 600;
    text-transform: capitalize;
}

.left_modal .des_modal {
    text-align: left;
    color: var(--dark-black-color) !important;
    padding: 0 0 0px 30px;
}

h5.MuiTypography-root.MuiTypography-h5.right_test_modal.css-zq6grw {
    color: var(--dark-black-color);
}

p.about_text {
    color: #444444;
}


h5.MuiTypography-root.MuiTypography-h5.right_test_modal.css-ag7rrr-MuiTypography-root {
    color: var(--black-color) !important;
    text-transform: capitalize;
}

.MuiDialogActions-root.MuiDialogActions-spacing.css-1vskg8q {
    padding-bottom: 20px;
}


.MuiDialogActions-root.MuiDialogActions-spacing.css-knqc4i-MuiDialogActions-root {
    padding-bottom: 20px;
}

button.modal_button {
    color: white;
    background: #54a154;

}

.blog_main {
    background-color: var(--background-color-black-paragrpah);
    padding: 60px 0px 55px 0px !important;
}

.MuiCardMedia-root.css-pqdqbj {
    height: 180px;
    width: 100%;
}

.blog_card {
    border: 1px solid #8b949e;
    border-radius: 5px;
    margin-top: 30px;
}

.blog_card .css-16cvb1l-MuiCardMedia-root {
    border-radius: 5px;
    height: 180px;
    box-shadow: 0px 0px 1px 0px #000;
}

.blog_card .css-h93ljk-MuiTypography-root {
    color: #509e53;
    font-size: 17px !important;
    font-weight: 600 !important;
    padding: 10px 0px;
}

.blog_card p.MuiTypography-root.MuiTypography-body2.blog_title2.css-r40f8v-MuiTypography-root {
    font-size: 15px;
    color: var(--paragraph-color);
    padding-bottom: 10px !important;
    text-shadow: 0px 0px 1px;
}

h4.blog_detail_sub {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blog_read_more {
    border-radius: 3px !important;
    cursor: pointer !important;
    color: #509e53 !important;
    font-weight: 700 !important;
    padding: 8px 30px !important;
    text-transform: uppercase;
    margin-bottom: 25px !important;
    border: 2px solid #509e53 !important;
    margin-left: 8px !important;
}

.blog_read_more:hover {
    box-shadow: inset 0 100px 0 0 #191e25;
    color: var(--white-color) !important;
    transition: 1s;
    border: 2px solid #191e25 !important;
}

.placement-page-inner label {
    color: #383838 !important;
    font-size: 16px;
    font-weight: 600;
}

.placement-inner .form-control::placeholder {
    color: #636363 !important;
    font-size: 14px;
    font-weight: 600;
}

.placement-inner .questionAnswer-main .answer label {
    color: #494949 !important;
    font-size: 15px;
}

.blog_detail_form_main {
    position: sticky;
    top: 15% !important;
    left: 0;
    width: 100%;
    color: #fff;
}

.blog_detail_form_main .hire_developer_form select {
    height: 50px;
    max-height: 50px !important;
    width: 100%;
    border-radius: 5px;
    border: 2px solid #fff;
    font-size: 18px !important;
    padding-left: 10px !important;
    color: #fff;
    background: transparent;
}

.blog_detail_form_main option {
    background: #72b755;
}

.blog_detail_form_main input::placeholder {
    opacity: 1 !important;
    color: #fff !important;
    font-size: 16px !important;
}

.blog_detail_form_main .card {
    padding: 30px 40px 41px 40px !important;
    background-image: unset !important;
    background-color: #509e53 !important;
}

.blog_detail_form_main .card button.btn.btn-contained {
    padding: 10px 30px !important;
    box-shadow: unset !important;
    margin-top: -1px;
    color: #fff !important;
    width: 100% !important;
    letter-spacing: 1px !important;
    background: #191e25;
    font-weight: 600;
}

input#inputPassword5 {
    margin-bottom: 4px;
}

textarea#floatingTextarea2 {
    margin-top: 25px;
}

.blog_inner_page_data {
    padding: 70px 0px;
    background: #fff;
}

.one_side_text_inner_blog h3 {
    font-size: 27px !important;
    font-weight: 600;
    margin: 20px 0px !important;
}

.one_side_text_inner_blog h4 {
    font-size: 22px !important;
    font-weight: 600;
    margin: 20px 0px !important;
}

.contact_shopify {
    color: #191e25 !important;
}

.form_inner .card p {
    color: #fff;
}

.contact_shopify {
    color: var(--background-color-black-paragrpah) !important;
}

.main_content_shopifypg.row .contact_shopify {
    color: #191e25 !important;
}

a {
    color: unset;
}

button {
    padding: 8PX 30PX !important;
}

.our-services .services-inner .read-btnn {
    margin-bottom: 0px !important;
    margin-top: 20px !important;
}

.contact_us_section .contact_us_first .col-md-6 h5.MuiTypography-root.MuiTypography-h5.css-zq6grw {
    color: var(--black-color) !important;
}

.contact_us_section .contact_us_first .col-md-6 span.MuiTypography-root.MuiTypography-p.css-1uk1gs8 {
    color: var(--paragraph-color) !important;
}

.MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.css-12rl710-MuiPaper-root-MuiDialog-paper {
    box-shadow: 0px 0px 7px 0px #d0ff90 !important;
    border-radius: 15px !important;
}

.iner_mains_blog .blog_detail_form_main .card {
    border-radius: 10px !important;
}

.iner_mains_blog .blog_detail_form_main .card input {
    border: 2px solid #fff;
    border-radius: 5px !important;
    height: 12px !important;
    color: #fff !important;
}

a.hire_link:hover {
    color: var(--white-color);
}

.contact_form.modal_form button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    background: transparent;

}

.contact_form.modal_form button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-1yxmbwk {
    background: transparent;
}

h4.MuiTypography-root.MuiTypography-h3.title_modal.css-18h9l83 {
    font-size: 35px;
    padding: 20px 0px 0px 30px;
    font-weight: 600;
    text-transform: capitalize;
}

h4.MuiTypography-root.MuiTypography-h4.title_modal.css-1xvinid {
    color: #fff;
    padding: 26px 0 0;
    text-align: center;
    text-transform: capitalize;
    font-family: Didact Gothic, sans-serif !important;
    font-weight: 700;
    letter-spacing: 1px;
}

button.close_modal_btn {
    color: var(--dark-black-color);
    left: 83%;
}

button.close_modal_btn:hover {
    color: var(--dark-black-color);
    left: 83%;
    background: transparent;
}

h5.MuiTypography-root.MuiTypography-h5.right_test_modal.css-zq6grw {
    font-size: 32px;
    text-align: center;
    font-family: Didact Gothic, sans-serif !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.hidedropdown {
    display: none !important;
}

.MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom.blog_title1.css-t1nuxs {
    color: #509e53;
    font-size: 17px !important;
    font-weight: 600 !important;
    padding: 10px 0px;
}

p.MuiTypography-root.MuiTypography-body2.blog_title2.css-u30lmt {
    font-size: 15px;
    color: var(--paragraph-color);
    padding-bottom: 10px !important;
    text-shadow: 0px 0px 1px;
}

.seo_audit_btn a.seo_link {
    border: none !important;
}

.blog_detail_form_main .card button.btn.btn-contained:hover {
    box-shadow: inset 0px 100px 0px 0px #fff !important;
    transition: 2s !important;
    color: #000 !important;
    border: 1px solid #fff !important;
}

/* 01-07-2024 contact us form css start here */
.right_contact.col-md-6 {
    background-color: #63696980;
    border-radius: 10px;
}

.right_contact button.form_submit_btn {
    border: 2px solid;
    background-color: #fff !important;
}

.right_contact .form-control {
    border: 2px solid #fff !important;
}

.right_contact input::placeholder {
    color: #fff !important;
}

/* button.form_submit_btn {
    padding: 10px;
    height: 50px;
    width: 100%;
    color: black;
    font-weight: 600;
    background-color: white;
} */

.contact_us_section .container .right_contact .col-md-6 form button:hover {
    color: #fff !important;
    background-color: #191e25 !important;
}


.social_media_hover ul {
    position: relative;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    margin: 0;
    padding: 0;
}

.social_media_hover ul li {
    list-style: none;
    margin: 0 5px;
}

.social_media_hover ul li a .fa {
    font-size: 40px;
    color: #262626;
    line-height: 80px;
    transition: .5s;
    padding-right: 14px;
}

.social_media_hover ul li a span {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 30px;
    color: #262626;
    letter-spacing: 4px;
    transition: .5s;
}

.social_media_hover ul li a {
    text-decoration: none;
    display: absolute;
    display: block;
    width: 210px;
    height: 80px;
    background-color: #fff;
    text-align: left;
    padding-left: 20px;
    transform: rotate(-30deg) skew(25deg) translate(0, 0);
    transition: .5s;
    box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
}

.social_media_hover ul li a::before {
    content: "";
    position: absolute;
    top: 10px;
    left: -20px;
    height: 100%;
    width: 20px;
    background: #b1b1b1;
    transform: 0.5s;
    transform: rotate(0deg) skewY(-45deg);
}

.social_media_hover ul li a::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: -10px;
    height: 20px;
    width: 100%;
    background-color: #b1b1b1;
    transform: .5s;
    transform: rotate(0deg) skewX(-45deg);
}

.social_media_hover ul li a:hover {
    transform: rotate(-30deg) skew(25deg) translate(20px, -15px);
    box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
}

.social_media_hover ul li:hover .fa {
    color: #fff;
}

.social_media_hover ul li:hover span {
    color: #fff;
}

.social_media_hover ul li:hover:nth-child(1) a {
    background: #3b5998;
}

.social_media_hover ul li:hover:nth-child(1) a::before {
    background: #3b5998;
}

.social_media_hover ul li:hover:nth-child(1) a::after {
    background: #4a69ad;
}

.social_media_hover ul li:hover:nth-child(2) a {
    background: #00aced;
}

.social_media_hover ul li:hover:nth-child(2) a::before {
    background-color: #097aa5;
}

.social_media_hover ul li:hover:nth-child(2) a::after {
    background: #53b9e0;
}

.social_media_hover ul li:hover:nth-child(3) a {
    background: #6cd870;
}

.social_media_hover ul li:hover:nth-child(3) a::before {
    background-color: #509e53;
}

.social_media_hover ul li:hover:nth-child(3) a::after {
    background: #255226;
}

.social_media_hover ul li:hover:nth-child(4) a {
    background: #e4405f;
}

.social_media_hover ul li:hover:nth-child(4) a::before {
    background-color: #d81c3f;
}

.social_media_hover ul li:hover:nth-child(4) a::after {
    background: #e46880;
}

.social_media_hover {
    height: 150px;
    background-color: #ccc;
    padding-bottom: 120px;
    margin-left: 100px;
}

.social_media_hover svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    margin-bottom: -40px;
    font-size: 40px;
    color: #000;
}

.social_media_hover svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    margin-bottom: -40px;
    font-size: 40px;
    color: #000;
}


.social_media_hover ul li {
    list-style: none;
    margin-top: 65px;
}

.social-media {
    padding-top: 0px !important;
}

.contact_us_first {
    border-top: 3px solid #509e53;
}

.social_efeect:hover a svg {
    color: white !important;
}

/* 02-08-2024 css start here */
h2.cont2 a {
    text-transform: capitalize;
    color: #509e53;
}

p.error_mesg {
    margin-left: 12px;
}

textarea#message_textarea:focus {
    box-shadow: none;
    border: 2px solid #1b6ef3;
    /* Focus border color */
}

/* Style for textarea when it is hovered but not focused */
textarea#message_textarea:hover {
    box-shadow: none;
    border: 1px solid var(--black-color);
    /* Hover border color */
}

/* Ensure that focused textarea styles are applied and hover styles are not applied when focused */
textarea#message_textarea:focus:hover {
    border: 2px solid #1b6ef3;
    /* Keep the focus border color */
}

h2.contact_use_heading {
    color: var(--black-color);
    line-height: 1.2;
    padding-left: 51px;
    text-align: left;
    margin-top: 3px;
    text-transform: capitalize;
}

.invalid-feedbacks {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
    margin-left: 10px;
}

.invalid-feedback {
    margin-left: 10px;
}

.left_modal_iner {
    height: 450px;
    padding: 20px;
    color: #fff;
    /* box-shadow: inset 0px 0px 0px 2000px #000000bd; */
}

section.team_section.meeting_team_section .cards .card .card-content-wrapper:hover .card-content .header_btn_dot a {
    color: var(--dark-black-color) !important;
}

section.team_section.meeting_team_section .card:hover .header_btn_dot a,
section.team_section.meeting_team_section .card-img-wrapper:hover .header_btn_dot a,
section.team_section.meeting_team_section .card-content:hover .header_btn_dot a {
    color: var(--dark-black-color) !important;
}

.left_modal.col-md-6 {
    padding: 0;
}

.contact_form.modal_form.modal_form_error {
    padding-top: 131px;
}

.exp_btn.meetin_btn_footer a {
    background: #54a154;
    border: 1px solid #54a154;
}

.exp_btn.meetin_btn_footer {
    margin-bottom: 19px;
    margin-left: 5px;
}


h2#alert-dialog-title {
    justify-content: center;
}

button.meeeting_btn {
    position: fixed;
    bottom: 116px;
    background: #54a154;
    border: transparent;
    right: 50px;
    z-index: 1;
    border-radius: 50%;
    height: 40px;
    width: 40px !important;
    color: white;
    padding: 0px !important;
}

button.meeeting_btn:hover {
    background: #509e53;
}

.meeting_links a {
    text-decoration: none;
}

p.linkss {
    text-transform: capitalize;
    color: white;
    background: #28a745;
    padding: 8px;
    text-align: center;
    border-radius: 6px;
    text-decoration: none !important;
    border-bottom: 0px !important;
}

h2.meeting_title {
    color: black;
    font-size: 22px !important;
    width: 400px;
}

button.meeting_close_btn {
    padding: 0px !important;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #28a745;
    color: #fff;
    border-bottom-left-radius: 15px !important;
    margin-right: -8px;
    margin-top: -10px;
    border-radius: 0px;
}

button.meeting_close_btn:hover {
    background: #28a745 !important;
}

.column.footer-address-main a:hover {
    color: #509e53 !important;
}

.whatsapp_shopify a:hover {
    color: #509e53;
}

.mail_shopify a:hover {
    color: #509e53;
}

.whatsapp_shopify p:hover {
    color: #509e53;
}

.mail_shopify p:hover {
    color: #509e53;
}

img.space_sqaure {
    height: 50px;
    width: 50px;
    margin-left: -10px;
}

textarea.input.message_text::placeholder {
    color: #f4ffff;
}

textarea.input.message_text:focus {
    border: 2px solid rgb(3, 121, 168);
}
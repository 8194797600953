/*      */
p.title_fraud_contenttext {
    padding-bottom: 20px;
}
.uppersection_fraud {
    background-color: white;
}
.uppersection_fraud h1, .uppersection_fraud h2,.uppersection_fraud h3, .uppersection_fraud h4,.uppersection_fraud h5, .uppersection_fraud h6,.uppersection_fraud b{
    color: #292b32;
    padding-bottom: 20px;
}
.terms_conditions_inner {
    padding: 20px;
}
.fraud_section {
    background: #ffff;
    width: 80%;
    margin: 0 auto !important;
    padding: 50px 50px 30px 50px;
    box-shadow: 0px 0px 6px 0px #ccc;
}
.terms_conditions_title {
    font-size: 48px;
    text-align: center;
    margin-bottom: 30px;
}

.popup_fraud_content {
    margin-bottom: 30px;
}

.fake_channels {
    margin-bottom: 20px;
    background: #ccc;
    padding: 20px;
}

.text_red {
    color: red;
}

.fs_26 {
    font-size: 26px;
}

.fs_20 {
    font-size: 20px;
}

.fs_30 {
    font-size: 30px;
}

.terms_para {
    margin-bottom: 15px;
}

.terms_spb {
    margin-bottom: 20px;
}

.terms_inner_headings {
    font-size: 24px;
    margin-bottom: 10px;
}

.fake_channels span.fake {
    color: #f70000;
}

.sec_clr {
    font-size: 20px;
    color: #509e53;
    font-weight: 600;
    font-family: "Didact Gothic", sans-serif !important;
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 10px;
    }

    .terms_conditions_title {
        font-size: 34px;
    }

    .fs_48 {
        font-size: 36px;
    }

    .fs_34 {
        font-size: 28px;
    }

    .fs_26 {
        font-size: 22px;
    }

    .fs_20 {
        font-size: 18px;
    }

    .fs_30 {
        font-size: 26px;
    }
}

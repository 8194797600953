.tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 0;
}
.tabs li {
    display: block; 
        padding: 10px;
        text-align: center;
        background-color: #0e0e0e;
        border: 1px solid #1c1c1c;
        cursor: pointer;
        color: #fff !important;
        margin: 0px 0px 15px;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 300;
        width: 150px;
    }
.tabs li.active {
    background: #54a154;
    color: #fff;
    padding: 9px 15px;
}
.tab_content {
    padding: 0px 0px 20px;
    background: #000;
    margin-top: 10px;
    border-radius: 5px;
    margin: auto;
    color: #fff;
}
.tab-inner .column img {
    width: 100%;
    border-radius: 8px;
}
.tabs {
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 0;
}
.tab_panel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.tab_panel .column {
    padding: 0;
    margin: 10px;
}
.tab_panel h3 {
    font-size: 28px;
    font-weight: 700;
}
.tab_inner_project {
    background: #000;
    padding: 50px 0px 30px;
}
.tabs li:hover {
    /* transform: scale(1.1); */
    box-shadow: 0px 0px 11px #2125293d;
    transition: .3s;
    display: block;
    padding: 10px 23px;
    background: #54a154 !important;
    margin-right: 12px;
    border-radius: 6px;
    cursor: pointer;
    color: #fff !important;
}
h3.tab-heading {
    text-align: center;
    color: #fff;
    padding-bottom: 30px;
    font-size: 34px;
    width: 80%;
    margin: auto;
    font-weight: 600;
    padding-top: 12px;
}
.protpolio-clm {
    display: block;
    height: 192px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}
.port_content {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 0px;
    margin: 0 auto;
}
.screen_port {
    display: block;
    height: 313px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    margin: 0 auto;
}
.screen_port img {
    width: 100%;
    height: auto; 
    z-index: 0;
    margin: 0;
    padding: 0;
    -webkit-transition: transform 12s ease;
    -moz-transition: transform 12s ease;
    -ms-transition: transform 12s ease;
    -o-transition: transform 12s ease;
    transition: transform 12s ease;
    transform: translateY(0%);
}
.screen_port:hover img {
    transform: unset;
}
.php_image {
    height: 180px !important;
}
